import React, { lazy } from 'react'

const HelpdeskMasterListing = lazy(() =>
  import('./HelpdeskMaster/HelpdeskMasterListing').then(module => ({
    default: module.HelpdeskMasterListing,
  }))
)

const AssigneeHelpdeskListing = lazy(() =>
  import('./HelpdeskAssignee/AssigneeHelpdeskListing').then(module => ({
    default: module.AssigneeHelpdeskListing,
  }))
)

const HelpdeskTicketForm = lazy(() =>
  import('./HelpdeskTicketForm').then(module => ({
    default: module.HelpdeskTicketForm,
  }))
)

const HelpdeskTicketDetail = lazy(() =>
  import('./HelpdeskTicketDetail').then(module => ({
    default: module.HelpdeskTicketDetail,
  }))
)

const MyTicketsHelpdeskListing = lazy(() =>
  import('./HelpdeskEmployee/MyTicketsHelpdeskListing').then(module => ({
    default: module.MyTicketsHelpdeskListing,
  }))
)

const MyTicketsHelpdeskForm = lazy(() =>
  import('./HelpdeskEmployee/MyTicketsHelpdeskForm').then(module => ({
    default: module.MyTicketsHelpdeskForm,
  }))
)

const MyTicketsHelpdeskDetail = lazy(() =>
  import('./HelpdeskEmployee/MyTicketsHelpdeskDetail').then(module => ({
    default: module.MyTicketsHelpdeskDetail
  }))
)

const HelpdeskRoutes = [
  {
    props: { exact: true, path: '/HelpdeskMaster/HelpdeskMasterListing' },
    component: <HelpdeskMasterListing />,
  },
  {
    props: { exact: true, path: '/HelpdeskAssignee/AssigneeHelpdeskListing' },
    component: <AssigneeHelpdeskListing />,
  },
  {
    props: { exact: true, path: '/HelpdeskTicketForm/Master' },
    component: <HelpdeskTicketForm mode="master" />,
  },
  {
    props: { exact: true, path: '/HelpdeskTicketForm/Assignee' },
    component: <HelpdeskTicketForm mode="assignee" />,
  },
  {
    props: { exact: true, path: '/HelpdeskTicketDetail' },
    component: <HelpdeskTicketDetail />,
  },
  {
    props: { exact: true, path: '/MyTickets/MyTicketsListing' },
    component: <MyTicketsHelpdeskListing />,
  },
  {
    props: { exact: true, path: '/MyTickets/MyTicketsListing/Add' },
    component: <MyTicketsHelpdeskForm />,
  },
  {
    props: { exact: true, path: '/MyTickets/MyTicketsListing/Details' },
    component: <MyTicketsHelpdeskDetail />,
  },
]

export default HelpdeskRoutes
