import { Button } from '@material-ui/core'
import { NavigateBeforeSharp } from '@material-ui/icons'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import { flexbox } from '@material-ui/system'

import React from 'react'
interface IFooterProps {
  options?: any
  chipLabel?: string
  value?: string | number | any
  single?: Boolean
  isHidden?: boolean
  totalAmt?: boolean
  extraNote?: string
  showDollarIcon?: boolean
  LabelFontColor?: string
  LabelColor?: string
  ChipLabelColor?: string
  background?: any
  externalDisplay?: boolean
}
export const Footer = (props: IFooterProps) => {
  const {
    chipLabel,
    options,
    value,
    isHidden,
    totalAmt,
    extraNote,
    showDollarIcon,
    LabelFontColor,
    ChipLabelColor,
    LabelColor,
    background,
    externalDisplay,
  } = props
  let single: boolean = false

  if (options?.length === 1) {
    single = true
  }

  return (
    <div
      className={externalDisplay ? 'externalFooter' : 'footer'}
      id="footer"
      style={{ zIndex: 999 }}
    >
      {chipLabel && (
        <div
          className="footer-label"
          style={{ color: LabelFontColor, backgroundColor: LabelColor }}
        >
          <div
            className="chip-label"
            style={{ color: LabelFontColor, backgroundColor: ChipLabelColor }}
          >
            {chipLabel}
          </div>

          <span className="label-value">
            {showDollarIcon ? (
              <div
                style={{
                  display: 'inline-flex',
                  marginLeft: 'auto',
                  alignItems: 'center',
                }}
              >
                <MonetizationOnIcon
                  style={{ fontSize: '13px', paddingRight: '5px' }}
                />
                {value}
              </div>
            ) : (
              value
            )}
          </span>
        </div>
      )}
      {totalAmt && (
        <div
          className="footer-label"
          style={{
            background: '#faf2e8',
          }}
        >
          <div className="xsTitle">
            Total: <span className="color-primary-orange">{value}</span>
          </div>
        </div>
      )}
      {extraNote && (
        <div
          className="footer-label"
          style={{
            background: '#faf2e8',
          }}
        >
          <div className="desc text-noflow" style={{ textAlign: 'center' }}>
            <span style={{ color: 'red' }}>{extraNote}</span>
          </div>
        </div>
      )}
      {isHidden ? null : (
        <div className="footer-btn">
          {options.map((el, index) => (
            <Button
              key={index}
              type="submit"
              color={el.color}
              style={{ minWidth: options.length > 2 ? '50px' : '126px' }}
              variant="contained"
              className={single ? 'single' : ''}
              onClick={el.onClick}
              disabled={el.disabled}
              {...el.props}
            >
              {el.name}
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}
