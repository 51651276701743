import React, { lazy } from 'react'


const CalendarPolicy = lazy(() =>
  import('./CompanySummary/CalendarPolicy/CalendarPolicy').then(module => ({
    default: module.CalendarPolicy,
  }))
)

const CalendarPolicyListing = lazy(() =>
  import('./CompanySummary/CalendarPolicy/CalendarPolicyListing').then(module => ({
    default: module.CalendarPolicyListing,
  }))
)

const NewCalendarPolicy = lazy(() =>
  import('./CompanySummary/CalendarPolicy/NewCalendarPolicy').then(module => ({
    default: module.NewCalendarPolicy,
  }))
)


const YearlyGroupHolidayForm = lazy(() =>
  import('./CompanySummary/CalendarPolicy/YearlyGroupHolidayForm').then(module => ({
    default: module.YearlyGroupHolidayForm,
  }))
)



const CompanySummaryRoutes = [

  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CalendarPolicyListing/CalendarPolicy',
    },
    component: <NewCalendarPolicy />,
    // component: <CalendarPolicy />,

  },
  {
    props: { exact: true, path: '/SystemAdmin/Company/CalendarPolicyListing' },
    component: <CalendarPolicyListing />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/Company/CalendarPolicyListing/CalendarPolicy/YearlyGroupHolidayForm' },
    component: <YearlyGroupHolidayForm />,
  },

]

export default CompanySummaryRoutes
