import React, { lazy } from 'react'

const ApprovedProjectExpenseClaimVerification = lazy(() =>
  import(
    './ApprovedProjectExpenseClaimVerification/ApprovedProjectExpenseClaimVerification'
  ).then(module => ({
    default: module.ApprovedProjectExpenseClaimVerification,
  }))
)

const ApprovedProjectExpenseClaimVerificationDetails = lazy(() =>
  import(
    './ApprovedProjectExpenseClaimVerification/ApprovedProjectExpenseClaimVerificationDetails'
  ).then(module => ({
    default: module.ApprovedProjectExpenseClaimVerificationDetails,
  }))
)

const ProjectExpenseClaimApplication = lazy(() =>
  import(
    './ProjectExpenseClaimApplication/ProjectExpenseClaimApplication'
  ).then(module => ({
    default: module.ProjectExpenseClaimApplication,
  }))
)

const ProjectExpenseClaimApplicationForm = lazy(() =>
  import(
    './ProjectExpenseClaimApplication/ProjectExpenseClaimApplicationForm'
  ).then(module => ({
    default: module.ProjectExpenseClaimApplicationForm,
  }))
)
const ProjectExpenseClaimApplicationDetail = lazy(() =>
  import(
    './ProjectExpenseClaimApplication/ProjectExpenseClaimApplicationDetail'
  ).then(module => ({
    default: module.ProjectExpenseClaimApplicationDetail,
  }))
)
const StaffProjectCostAllocationEmployeeListing = lazy(() =>
  import(
    './StaffProjectCostAllocation/StaffProjectCostAllocationEmployeeListing'
  ).then(module => ({
    default: module.StaffProjectCostAllocationEmployeeListing,
  }))
)

const StaffProjectCostAllocationPeriodListing = lazy(() =>
  import(
    './StaffProjectCostAllocation/StaffProjectCostAllocationPeriodListing'
  ).then(module => ({
    default: module.StaffProjectCostAllocationPeriodListing,
  }))
)

const StaffProjectCostAllocationForm = lazy(() =>
  import('./StaffProjectCostAllocation/StaffProjectCostAllocationForm').then(
    module => ({
      default: module.StaffProjectCostAllocationForm,
    })
  )
)
const ProjectExpenseClaimItemForm = lazy(() =>
  import('./ProjectExpenseClaimApplication/ProjectExpenseClaimItemForm').then(
    module => ({
      default: module.ProjectExpenseClaimItemForm,
    })
  )
)

const ProjectExpenseCompanySetting = lazy(() =>
  import('./CompanySetting/ProjectExpenseCompanySetting').then(module => ({
    default: module.ProjectExpenseCompanySetting,
  }))
)

const ProjectAccountClaimSetting = lazy(() =>
  import(
    './CompanySetting/ProjectAccountClaimSetting/ProjectAccountClaimSetting'
  ).then(module => ({
    default: module.ProjectAccountClaimSetting,
  }))
)

const ClaimApprovalPolicyAssignment = lazy(() =>
  import(
    './CompanySetting/ProjectExpenseClaimApprovalPolicyAssignment/ClaimApprovalPolicyAssignment'
  ).then(module => ({
    default: module.ClaimApprovalPolicyAssignment,
  }))
)

const GLAccountMappingAssignment = lazy(() =>
  import(
    './CompanySetting/GLAccountMappingAssignment/GLAccountMappingAssignment'
  ).then(module => ({
    default: module.GLAccountMappingAssignment,
  }))
)

const ProjectExpenseClaimPostingDetail = lazy(() =>
  import('./ProjectExpenseClaimPosting/ProjectExpenseClaimPostingDetail').then(
    module => ({
      default: module.ProjectExpenseClaimPostingDetail,
    })
  )
)

const ProjectExpenseClaimPostingListing = lazy(() =>
  import('./ProjectExpenseClaimPosting/ProjectExpenseClaimPostingListing').then(
    module => ({
      default: module.ProjectExpenseClaimPostingListing,
    })
  )
)

const ProjectExpenseClaimPostingMonthListing = lazy(() =>
  import(
    './ProjectExpenseClaimPosting/ProjectExpenseClaimPostingMonthListing'
  ).then(module => ({
    default: module.ProjectExpenseClaimPostingMonthListing,
  }))
)

const ProjectExpenseClaimPaymentDetail = lazy(() =>
  import('./ProjectExpenseClaimPayment/ProjectExpenseClaimPaymentDetail').then(
    module => ({
      default: module.ProjectExpenseClaimPaymentDetail,
    })
  )
)

const ProjectExpenseClaimPaymentListing = lazy(() =>
  import('./ProjectExpenseClaimPayment/ProjectExpenseClaimPaymentListing').then(
    module => ({
      default: module.ProjectExpenseClaimPaymentListing,
    })
  )
)

const ProjectExpenseClaimPaymentMonthListing = lazy(() =>
  import(
    './ProjectExpenseClaimPayment/ProjectExpenseClaimPaymentMonthListing'
  ).then(module => ({
    default: module.ProjectExpenseClaimPaymentMonthListing,
  }))
)

const GLExportListing = lazy(() =>
  import('./GLExport/GLExportListing').then(module => ({
    default: module.GLExportListing,
  }))
)

const PayrollGLPayPeriodListing = lazy(() =>
  import('./GLExport/PayrollGLExport/PayrollGLPayPeriodListing').then(
    module => ({
      default: module.PayrollGLPayPeriodListing,
    })
  )
)

const PayrollGLListing = lazy(() =>
  import('./GLExport/PayrollGLExport/PayrollGLListing').then(module => ({
    default: module.PayrollGLListing,
  }))
)

const PayrollGLDetailsView = lazy(() =>
  import('./GLExport/PayrollGLExport/PayrollGLDetailsView').then(module => ({
    default: module.PayrollGLDetailsView,
  }))
)

const ClaimGLListing = lazy(() =>
  import('./GLExport/ClaimGLExport/ClaimGLListing').then(module => ({
    default: module.ClaimGLListing,
  }))
)

const ClaimGLDetailsView = lazy(() =>
  import('./GLExport/ClaimGLExport/ClaimGLDetailsView').then(module => ({
    default: module.ClaimGLDetailsView,
  }))
)

const GLProcessingListing = lazy(() =>
  import('./GLProcessing/GLProcessingListing').then(module => ({
    default: module.GLProcessingListing,
  }))
)
const PayrollGLCycleListing = lazy(() =>
  import('./GLProcessing/PayrollGLProcessing/PayrollGLCycleListing').then(
    module => ({
      default: module.PayrollGLCycleListing,
    })
  )
)

const PayrollGLProcessingPayPeriodListing = lazy(() =>
  import(
    './GLProcessing/PayrollGLProcessing/PayrollGLProcessingPayPeriodListing'
  ).then(module => ({
    default: module.PayrollGLProcessingPayPeriodListing,
  }))
)

const PayrollGLProcessingListing = lazy(() =>
  import('./GLProcessing/PayrollGLProcessing/PayrollGLProcessingListing').then(
    module => ({
      default: module.PayrollGLProcessingListing,
    })
  )
)

const PayrollGLProcessingDetailsView = lazy(() =>
  import(
    './GLProcessing/PayrollGLProcessing/PayrollGLProcessingDetailsView'
  ).then(module => ({
    default: module.PayrollGLProcessingDetailsView,
  }))
)

const ClaimGLProcessingListing = lazy(() =>
  import('./GLProcessing/ClaimGLProcessing/ClaimGLProcessingListing').then(
    module => ({
      default: module.ClaimGLProcessingListing,
    })
  )
)

const ClaimGLProcessingDetailsView = lazy(() =>
  import('./GLProcessing/ClaimGLProcessing/ClaimGLProcessingDetailsView').then(
    module => ({
      default: module.ClaimGLProcessingDetailsView,
    })
  )
)

const ClaimGLCycleListing = lazy(() =>
  import('./GLProcessing/ClaimGLProcessing/ClaimGLCycleListing').then(
    module => ({
      default: module.ClaimGLCycleListing,
    })
  )
)

const ProjectExpenseRoutes = [
  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/CompanySetting/ProjectExpenseCompanySetting',
    },
    component: <ProjectExpenseCompanySetting />,
  },

  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/ApprovedProjectExpenseClaimVerification',
    },
    component: <ApprovedProjectExpenseClaimVerification />,
  },

  {
    props: {
      exact: true,
      path:
        '/ProjectExpenseModule/ApprovedProjectExpenseClaimVerification/ApprovedProjectExpenseClaimVerificationDetails',
    },
    component: <ApprovedProjectExpenseClaimVerificationDetails />,
  },

  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/ProjectExpenseClaimApplication/form/add',
    },
    component: <ProjectExpenseClaimApplicationForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path:
        '/ProjectExpenseModule/ProjectExpenseClaimApplication/ProjectExpenseClaimApplicationDetail',
    },
    component: <ProjectExpenseClaimApplicationDetail />,
  },
  {
    props: {
      path:
        '/ProjectExpenseModule/ProjectExpenseClaimApplication/form/claimitem/add',
    },
    component: <ProjectExpenseClaimItemForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/ProjectExpenseClaimApplication',
    },
    component: <ProjectExpenseClaimApplication />,
  },
  {
    props: {
      exact: true,
      path:
        '/ProjectExpenseModule/CompanySetting/ProjectExpenseCompanySetting/ClaimSetting',
    },
    component: <ProjectAccountClaimSetting />,
  },
  {
    props: {
      exact: true,
      path:
        '/ProjectExpenseModule/CompanySetting/ProjectExpenseCompanySetting/ClaimApprovalPolicyAssignment',
    },
    component: <ClaimApprovalPolicyAssignment />,
  },
  {
    props: {
      exact: true,
      path:
        '/ProjectExpenseModule/CompanySetting/ProjectExpenseCompanySetting/GLAccountMappingAssignment',
    },
    component: <GLAccountMappingAssignment />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/CompanySetting/ProjectExpenseCompanySetting',
    },
    component: <ProjectExpenseCompanySetting />,
  },
  {
    props: {
      exact: true,
      path:
        '/ProjectExpenseModule/StaffProjectCostAllocation/StaffProjectCostAllocationEmployeeListing',
    },
    component: <StaffProjectCostAllocationEmployeeListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/ProjectExpenseModule/StaffProjectCostAllocation/StaffProjectCostAllocationPeriodListing',
    },
    component: <StaffProjectCostAllocationPeriodListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/ProjectExpenseModule/StaffProjectCostAllocation/StaffProjectCostAllocationForm',
    },
    component: <StaffProjectCostAllocationForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/ProjectExpenseModule/ProjectExpenseClaimApplication/ProjectExpenseClaimApplication',
    },
    component: <ProjectExpenseClaimApplication />,
  },
  {
    props: {
      exact: true,
      path:
        '/ProjectExpenseModule/ProjectExpenseClaimPosting/ProjectExpenseClaimPostingDetail',
    },
    component: <ProjectExpenseClaimPostingDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/ProjectExpenseModule/ProjectExpenseClaimPosting/ProjectExpenseClaimPostingListing',
    },
    component: <ProjectExpenseClaimPostingListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/ProjectExpenseModule/ProjectExpenseClaimPosting/ProjectExpenseClaimPostingMonthListing',
    },
    component: <ProjectExpenseClaimPostingMonthListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/ProjectExpenseModule/ProjectExpenseClaimPayment/ProjectExpenseClaimPaymentDetail',
    },
    component: <ProjectExpenseClaimPaymentDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/ProjectExpenseModule/ProjectExpenseClaimPayment/ProjectExpenseClaimPaymentListing',
    },
    component: <ProjectExpenseClaimPaymentListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/ProjectExpenseModule/ProjectExpenseClaimPayment/ProjectExpenseClaimPaymentMonthListing',
    },
    component: <ProjectExpenseClaimPaymentMonthListing />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/GLExport',
    },
    component: <GLExportListing />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/GLExport/Payroll/GLPayPeriodListing',
    },
    component: <PayrollGLPayPeriodListing />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/GLExport/Payroll/GLListing',
    },
    component: <PayrollGLListing />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/GLExport/Payroll/GLDetailsView',
    },
    component: <PayrollGLDetailsView />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/GLExport/Claim/GLListing',
    },
    component: <ClaimGLListing />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/GLExport/Claim/GLDetailsView',
    },
    component: <ClaimGLDetailsView />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/GLProcessing',
    },
    component: <GLProcessingListing />,
  },

  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/GLProcesing/Payroll/GLPayCycleListing',
    },
    component: <PayrollGLCycleListing />,
  },

  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/GLProcessing/Payroll/GLPayPeriodListing',
    },
    component: <PayrollGLProcessingPayPeriodListing />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/GLProcessing/Payroll/GLListing',
    },
    component: <PayrollGLProcessingListing />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/GLProcessing/Payroll/GLDetailsView',
    },
    component: <PayrollGLProcessingDetailsView />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/GLProcessing/Claim/GLListing',
    },
    component: <ClaimGLProcessingListing />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/GLProcessing/Claim/GLDetailsView',
    },
    component: <ClaimGLProcessingDetailsView />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectExpenseModule/GLProcessing/Claim/GLClaimCycleListing',
    },
    component: <ClaimGLCycleListing />,
  },
]

export default ProjectExpenseRoutes
