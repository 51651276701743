import { Browser } from '@capacitor/browser'

export const redirectToAnotherApp = async (
  path: string,
  module: string,
  token: string,
  Map,
  mobile = false
) => {
  // Replace this with the actual URL of the target app
  // Map come from Drawer.tsx

  var url = ''
  var currentMap = Map?.find(m => m.module == module)

  switch (process.env.REACT_APP_API_URL) {
    case 'ifca-build':
      url = currentMap?.ifca_url
      break
    case 'prod-v5-build':
      url = currentMap?.prod_url
      break
    case 'prod-clone-build':
      url = currentMap?.prod_clone_url
      break
    case 'uat-v5-build':
      url = currentMap?.uat_url
      break
    case 'dev-v2-build':
      url = currentMap?.dev_url
      break
    case 'prod-sehsa-build':
      url = currentMap?.sehsa_url
      break
    case 'prod-sehuk-build':
      url = currentMap?.sehuk_url
      break
    case 'prod-mbi-build':
      url = currentMap?.mbi_url
      break
    case 'demo-build':
      url = currentMap?.demo_url
      break
    case 'prod-eon-build':
      url = currentMap?.eon_url
      break
    case 'uat-eon-build':
      url = currentMap?.eon_uat_url
      break
    default:
      url = currentMap?.local_url
  }

  var prefix = ''
  switch (process.env.REACT_APP_API_URL) {
    case 'ifca-build':
    case 'prod-v5-build':
    case 'prod-clone-build':
    case 'uat-v5-build':
    case 'dev-v2-build':
    case 'prod-mbi-build':
    case 'demo-build':
    case 'prod-eon-build':
    case 'uat-eon-build':
      prefix = 'https'
      break
    default:
      prefix = 'http'
  }
  if (url && prefix && token) {
    if (mobile) {
      await Browser.open({
        url: `${prefix}://${url}${path}?mobile&userID=${token}`,
      })
    } else {
      window.location.href = `${prefix}://${url}${path}?token=${token}`
    }
  } else {
    window.alert('routing error!')
  }
  // window.location.href = `http://localhost:3008${path}?userID=${userID}`
}
