import { format, parseISO } from 'date-fns'
import moment from 'moment'

export const formatDate = (date: Date) =>
  new Date(date)
    .toDateString()
    .split(' ')
    .slice(1)
    .join(' ')

export const convertMinToHours = (minute: number) => {
  if (minute) {
    let hours = Math.floor(minute / 60)
    let minutes = minute % 60
    if (hours > 0 && minutes > 0) {
      return `${hours}h ${minutes}m`
    }
    if (hours == 0 && minutes > 0) {
      return `${minutes}m`
    }
    if (hours > 0 && minutes == 0) {
      return `${hours}h`
    }
  } else {
    return null
  }
}

export const getBuildDate = epoch => {
  const buildDate = moment(epoch).format('DD-MM-YYYY HH:MM')
  return buildDate
}

export const convertDate = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'dd/MM/yyyy hh:mm a') : 'NA'
}

export function timeElapsed(_datetime: Date, _now: Date) {
  var datetime = new Date(_datetime).getTime()
  var now = new Date(_now).getTime()

  //   if (isNaN(datetime)) {
  //     return ' on ' + _datetime;
  //   }

  if (datetime < now) {
    var milisec_diff = now - datetime
  } else {
    var milisec_diff = datetime - now
  }

  var days = Math.floor(milisec_diff / 1000 / 60 / (60 * 24))
  var hours = Math.floor(milisec_diff / (1000 * 60 * 60) - days * 24)
  var minutes = Math.floor(
    milisec_diff / (1000 * 60) - days * 24 * 60 - hours * 60
  )

  if (hours > 0 && minutes > 0) {
    return `${hours}h ${minutes}m`
  }
  if (minutes > 0 && hours == 0) {
    return `${minutes}m`
  }
  if (hours > 0 && minutes == 0) {
    return `${hours}h `
  }
  return `0h`
}

export function timeElapsedByMin(_datetime: Date, _now: Date) {
  var datetime = new Date(_datetime).getTime()
  var now = new Date(_now).getTime()

  //   if (isNaN(datetime)) {
  //     return ' on ' + _datetime;
  //   }

  if (datetime < now) {
    var milisec_diff = now - datetime
  } else {
    var milisec_diff = datetime - now
  }

  var minutes = Math.floor(milisec_diff / (1000 * 60))
  return minutes
}

export const dateValidity = (Date: string) => {
  console.log(Date, 'Date')
  // regular expression to match required date format
  let re = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/
  if (Date) {
    if (Date.match(re)) {
      return true
    }
    return false
  }

  return false
}
export const timeValidity = (Time: string) => {
  // regular expression to match required date format
  let re1 = /^\d{1,2}([:.]?\d{1,2})?([ ]?[a|p]m)?$/
  let re2 = /^\d{1,2}([:.]?\d{1,2})?([ ]?[A|P]M)?$/
  let re3 = /^\d{1,2}([:.]?\d{1,2})([:.]?\d{1,2})?([ ]?[A|P]M)?$/
  if (Time) {
    if (Time.match(re1) || Time.match(re2) || Time.match(re3)) {
      return true
    }
    return false
  }

  return false
}
