import React from 'react'
import empty from '../../assets/icons/empty.svg'
interface IEmptyListProps {
  title?: string
  subtitle?: string
  Dashboard?: boolean
}
const EmptyList = (props: IEmptyListProps) => {
  const { title, subtitle,Dashboard } = props
  return (
    Dashboard ? (
      <div className="empty-listing2">
      {/* <img src={empty} alt="empty-listing" width={300} /> */}

      <div style={{fontSize:'11px', fontWeight:'bold'}}>{title}</div>

      <div className="desc">{subtitle}</div>
    </div>
    ) : (
      <div className="empty-listing">
      {/* <img src={empty} alt="empty-listing" width={300} /> */}

      <div className="smTitle">{title}</div>

      <div className="desc">{subtitle}</div>
    </div>
    )
 
  )
}
export default EmptyList
