import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import dateFormat from 'dateformat'
import './submissiontable.scss'

export const ProjectTimeSheetSubmissionTable = (props: any) => {
  return (
    <>
      <div
        style={{
          position: 'fixed',
          height: '-webkit-fill-available',
          overflow: 'auto',
          background: 'white',
          borderRadius: '5px',
          marginBottom: props?.PageAction === 'View' ? null : '50px',
          boxShadow: '0 10px 20px -11px #888888',
        }}
        className={
          props?.Type === 'My ProjectTimesheet'
            ? 'summaryTable'
            : 'externalSummaryTable'
        }
      >
        <TableContainer
          style={{
            marginBottom: 12,
            marginTop: props?.Type === 'My ProjectTimesheet' ? 10 : 0,
            overflow: 'unset',
          }}
        >
          <Table className="table" stickyHeader>
            <TableBody>
              <TableRow
                style={{
                  backgroundColor: 'orange',
                  height: 10,
                  position: 'sticky',
                  top: 0,
                  zIndex: 2,
                }}
              >
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: 'orange',
                    color: 'white',
                    borderRight: '0.5px solid white',
                    boxShadow: 'unset',
                    borderBottom: '0.5px solid white',
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 0,
                    padding: 0,
                    paddingLeft: 7,
                    borderTopLeftRadius: '5px',
                  }}
                >
                  Date-Day
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    borderBottom: '0.5px solid white',
                    color: 'white',
                    borderRight: '0.5px solid white',
                  }}
                >
                  Day Type
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    borderBottom: '0.5px solid white',
                    color: 'white',
                    borderRight: '0.5px solid white',
                  }}
                >
                  Leave
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    borderBottom: '0.5px solid white',
                    color: 'white',
                    borderRight: '0.5px solid white',
                  }}
                >
                  Start Time
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    borderBottom: '0.5px solid white',
                    color: 'white',
                    borderRight: '0.5px solid white',
                  }}
                >
                  End Time
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    borderBottom: '0.5px solid white',
                    color: 'white',
                    borderRight: '0.5px solid white',
                  }}
                >
                  Break (Min)
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    borderBottom: '0.5px solid white',
                    color: 'white',
                    borderRight: '0.5px solid white',
                  }}
                >
                  Total Hours
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    borderBottom: '0.5px solid white',
                    color: 'white',
                    borderRight: '0.5px solid white',
                  }}
                >
                  Remarks
                </TableCell>
              </TableRow>

              {props?.SubmissionListing?.sort((a, b) =>
                a?.EntryDate > b?.EntryDate ? 1 : -1
              ).map(row => {
                return (
                  <>
                    <TableRow key={row?.Date}>
                      <TableCell
                        align="center"
                        className="table-row-sticky"
                        style={{
                          width: '9em',
                          background:
                            row?.DayType === 'PUBLIC_HOLIDAY'
                              ? '#99ff99'
                              : ['Rest Day', 'Off Day']?.includes(row?.DayType)
                              ? '#cccccc'
                              : '#fff5e7',
                          borderRight: '0.5px solid white',
                          borderBottom: '0.5px solid white',
                          boxShadow: 'unset',
                          position: 'sticky',
                          left: 0,
                          padding: 0,
                          paddingLeft: 7,
                          zIndex: 1,
                        }}
                      >
                        {dateFormat(row?.EntryDate, 'dd mmm yyyy ddd')}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          // #cccccc - grey
                          // #99ff99 - green
                          background:
                            row?.DayType === 'PUBLIC_HOLIDAY'
                              ? '#99ff99'
                              : ['Rest Day', 'Off Day']?.includes(row?.DayType)
                              ? '#cccccc'
                              : '#fff5e7',
                          borderBottom: '0.5px solid white',
                        }}
                        className="arrBackgroudColor"
                      >
                        {row?.DayType === 'PUBLIC_HOLIDAY'
                          ? 'Public Holiday'
                          : row?.DayType || '-'}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          background:
                            row?.DayType === 'PUBLIC_HOLIDAY'
                              ? '#99ff99'
                              : ['Rest Day', 'Off Day']?.includes(row?.DayType)
                              ? '#cccccc'
                              : '#fff5e7',
                          borderBottom: '0.5px solid white',
                        }}
                        className="arrBackgroudColor"
                      >
                        {row?.Leave || '-'}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          background:
                            row?.DayType === 'PUBLIC_HOLIDAY'
                              ? '#99ff99'
                              : ['Rest Day', 'Off Day']?.includes(row?.DayType)
                              ? '#cccccc'
                              : '#fff5e7',
                          borderBottom: '0.5px solid white',
                        }}
                        className="arrBackgroudColor"
                      >
                        {row?.StartTime
                          ? dateFormat(row?.StartTime, 'shortTime')
                          : '-'}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          background:
                            row?.DayType === 'PUBLIC_HOLIDAY'
                              ? '#99ff99'
                              : ['Rest Day', 'Off Day']?.includes(row?.DayType)
                              ? '#cccccc'
                              : '#fff5e7',
                          borderBottom: '0.5px solid white',
                        }}
                        className="arrBackgroudColor"
                      >
                        {row?.EndTime
                          ? dateFormat(row?.EndTime, 'shortTime')
                          : '-'}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          background:
                            row?.DayType === 'PUBLIC_HOLIDAY'
                              ? '#99ff99'
                              : ['Rest Day', 'Off Day']?.includes(row?.DayType)
                              ? '#cccccc'
                              : '#fff5e7',
                          borderBottom: '0.5px solid white',
                        }}
                        className="arrBackgroudColor"
                      >
                        {row?.BreakTime || '-'}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          background:
                            row?.DayType === 'PUBLIC_HOLIDAY'
                              ? '#99ff99'
                              : ['Rest Day', 'Off Day']?.includes(row?.DayType)
                              ? '#cccccc'
                              : '#fff5e7',
                          borderBottom: '0.5px solid white',
                        }}
                        className="arrBackgroudColor"
                      >
                        {row?.TotalHours || '-'}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          background:
                            row?.DayType === 'PUBLIC_HOLIDAY'
                              ? '#99ff99'
                              : ['Rest Day', 'Off Day']?.includes(row?.DayType)
                              ? '#cccccc'
                              : '#fff5e7',
                          borderBottom: '0.5px solid white',
                        }}
                        className="arrBackgroudColor"
                      >
                        {row?.Remarks || '-'}
                      </TableCell>
                    </TableRow>
                  </>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
}
