import React, { ReactNode } from 'react'

interface subHeaderProps {
  title?: any
  rightText?: any
  infoLine?: any
  subInfoLine?: ReactNode
}

export const FullDynamicHeader = ({
  infoLine,
  rightText,
  title,
  subInfoLine,
}: subHeaderProps) => {
  return (
    <div
      id="full-dynamic-header"
      className={`full-dynamic-header multiple`}
      style={{
        height: subInfoLine ? 'auto' : null,
      }}
    >
      <div id="subheader-content" style={{ width: '100%' }}>
        <div className="dynamic-content">
          <div className="session">
            <div className="title flex-space">{title}</div>
            {rightText && <div className="rightText">{rightText}</div>}
          </div>
        </div>
        <div
          className="infoline-content"
          style={{ display: subInfoLine ? 'block' : 'flex' }}
        >
          <div className="infoline" style={{ width: '100%' }}>
            {infoLine}
          </div>
          {subInfoLine && <div className="infoline m-t-2">{subInfoLine}</div>}
        </div>
      </div>
    </div>
  )
}
