import React, { lazy } from 'react'

const ShopeePersonnelImport = lazy(() =>
  import('./ShopeePersonnelImport').then(module => ({
    default: module.ShopeePersonnelImport
  }))
)
const ShopeePayPeriodListing  = lazy(() =>
import('./ShopeePayPeriodListing').then(module => ({
  default: module.ShopeePayPeriodListing
}))
)
const ShopeePayCycleListing  = lazy(() =>
import('./ShopeePayCycleListing').then(module => ({
  default: module.ShopeePayCycleListing
}))
)

// const ProcessShopeePayroll  = lazy(() =>
// import('./ProcessShopeePayroll').then(module => ({
//   default: module.ProcessShopeePayroll
// }))
// )


const ShopeeRoutes = [
  {
    props: { exact: true, path: '/ShopeePayPeriodListing/ShopeePayCycleListing/ShopeeImport' },
    component: <ShopeePersonnelImport />,
  },
  {
    props: { exact: true, path: '/ShopeePayPeriodListing' },
    component: <ShopeePayPeriodListing />,
  }
  ,  {
    props: { exact: true, path: '/ShopeePayPeriodListing/ShopeePayCycleListing' },
    component: <ShopeePayCycleListing />,
  }
  // ,  {
  //   props: { exact: true, path: '/ShopeePayPeriodListing/ShopeePayCycleListing/Import/Process' },
  //   component: <ProcessShopeePayroll />,
  // }
]

export default ShopeeRoutes