import {
  IconButton,
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import React, { ChangeEvent, useState } from 'react'

interface IInputUploadAllTypeProps extends StandardTextFieldProps {
  handleUpload: any
  id?: any
}
export const InputUploadAllType = (props: IInputUploadAllTypeProps) => {
  const { handleUpload, id, ...rest } = props
  const [value, setValue] = useState('')
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }
  const handleInput = () => {
    if (id) {
      document.getElementById(id).click()
    } else {
      document.getElementById('icon-button-file').click()
    }
  }

  return (
    <TextField
      {...rest}
      id={'input'}
      fullWidth
      // label="Attachment"
      onClick={handleInput}
      // value={value}
      // onChange={handleChange}
      InputProps={{
        endAdornment: (
          <>
            <InputAdornment position="end">
              <IconButton className="transform-180" aria-label="">
                <GetApp />
              </IconButton>
            </InputAdornment>
            <input
              accept=".pdf, .doc, .docx, .xls, .xlsx, image/*"
              hidden
              multiple
              onChange={e => {
                handleUpload(e)
              }}
              id={id ? id : 'icon-button-file'}
              type="file"
            />
          </>
        ),
      }}
    />
  )
}
