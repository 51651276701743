import DateFnsUtils from '@date-io/date-fns'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { MenuItem, TextField } from '@material-ui/core'
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { timeElapsed, timeElapsedByMin } from 'containers/helper/commonFormats'
import {
  Status,
  TimeOffListingDocument,
  TimeOffStatus,
  useTimeOffInsertMutation,
  useTimeOffListingQuery,
  useTimeOffReasonByTlaQuery,
  DocumentType,
  useGetEmployeeAppSecurityPermissionsByContactIdQuery,
  HrPermission,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { InputUploadAllType } from 'components/Input/InputUploadAllType'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'

interface TimeOffForm {
  TimeOffID?: string
  WorkDate?: Date
  StartTime?: Date
  EndTime?: Date
  ToReasonID?: string
  Remark?: string
  EmployeeID?: string
  TimeOffStatus?: string
  VisitingPlace: string
  TravelFrom: string
  TravelTo: string
}

export const TimeOffForm = (props: any) => {
  let history = useHistory()
  let location = useLocation()
  let formMode = 'New'

  const { state }: any = useLocation()
  const editData = location?.state as any
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const loggedInEmployee = JSON.parse(localStorage.getItem('loggedInEmployee'))
  const EmployeeDetails = JSON.parse(localStorage.getItem('Employee'))
  const [imagePreview, setImagePreview] = useState(
    state?.TimeOffListing?.Document?.DocumentFile || null
  )
  const [selectedFile, setSelectedFile] = useState({ files: [] })
  const [selectedFileName, setSelectedFileName] = useState(
    state?.TimeOffListing?.Document?.Description || ''
  )
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openSnackBar, setOpenSnackBar] = useState(false)

  const [disableConfirm, setDisableConfirm] = useState(false)

  const snackFunc = (showText: string, goBack: Boolean) => {
    setSnackBarMessage(showText)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
    }, 1000)
  }

  const {
    data: { TimeOffListing } = {
      TimeOffListing: [],
    },
  } = useTimeOffListingQuery({
    variables: {
      CompanyID: loggedInEmployee?.companyID,
      EmployeeID: loggedInEmployee.employeeID,
    },
    fetchPolicy: 'network-only',
  })
  const TimeOffListingByEmployee = TimeOffListing

  const {
    data: { TimeOffReasonByTLA } = {
      TimeOffReasonByTLA: [],
    },
  } = useTimeOffReasonByTlaQuery({
    variables: {
      CompanyID: loggedInEmployee?.companyID,
    },
    fetchPolicy: 'network-only',
  })

  
  const {
    data: { GetEmployeeAppSecurityPermissionsByContactID } = {
      GetEmployeeAppSecurityPermissionsByContactID: [],
    },
    loading : GetEmployeeAppLoading,
  } = useGetEmployeeAppSecurityPermissionsByContactIdQuery({
    fetchPolicy: 'no-cache',
    variables: {
      ContactID: JSON.parse(localStorage.getItem('Employee'))?.ContactID,
      Permissions: [
   
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuRead,
     
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuSubmit,
   
       
      

    
    
      ],
    },
  })

  const [selectedStartDate, handleStartDateChange] = useState(
    editData?.TimeOffListing?.StartTime || new Date()
  )
  const [selectedExpiryDate, handleExpiryDateChange] = useState(
    editData?.TimeOffListing?.EndTime || new Date()
  )

  const [TimeOffInsert, { loading: toLoading }] = useTimeOffInsertMutation({
    onError: error => {
      setError(`Remark`, {
        type: 'required',
        message: error.message.replace('GraphQL error:', ''),
      })
      setDisableConfirm(false)
    },
    onCompleted: data => {
      if (data.TimeOffInsert.TimeOffID !== '') {
        snackFunc('Insert Successfully!', false)
        setTimeout(() => {
          history.push(`/Employee/tms/timeofflist`)
        }, 500)
      } else {
        snackFunc('Insert Failed!', false)
        setDisableConfirm(false)
      }
    },
  })
  const [TOReason, setTOReason] = useState(editData?.editData?.ToReasonID || '')

  const onChangeFile = event => {
    let nextState = { ...selectedFile }
    nextState.files.splice(0, nextState.files.length)
    nextState.files.push(event.target.files[0])
    setSelectedFileName(event.target.files[0]?.name)
    setSelectedFile(nextState)
    let reader = new FileReader()

    reader.onloadend = () => {
      setImagePreview(reader.result)
    }
    reader.readAsDataURL(event.target.files[0])
  }

  const {
    handleSubmit,
    register,
    errors,
    control,
    setValue,
    setError,
    getValues,
    watch,
  } = useForm<TimeOffForm>({
    defaultValues: {
      Remark: editData?.TimeOffListing?.Remark,
      WorkDate: editData?.TimeOffListing?.WorkDate || new Date(),
      ToReasonID: editData?.TimeOffListing?.ToReasonID,
      EmployeeID: editData?.TimeOffListing?.EmployeeID,
      VisitingPlace: editData?.TimeOffListing?.VistingPlace,
      TravelFrom: editData?.TimeOffListing?.TravelFrom,
      TravelTo: editData?.TimeOffListing?.TravelTo,
    },
    mode: 'onBlur',
  })

  const refQuery = data => {
    return [
      {
        query: TimeOffListingDocument,
        variables: {
          CompanyID: loggedInEmployee?.companyID,
        },
      },
    ]
  }
  const onDraft = (data: TimeOffForm) => {
    setDisableConfirm(true)
    let Document: any = null
    let DocumentID: any = null
    const commonInput = {
      CreatedDT: new Date(),
      CreatedBy: loggedInEmployee?.employeeID,
      ModifiedDT: new Date(),
      ModifiedBy: loggedInEmployee?.employeeID,
    }
    if (selectedFile.files.length > 0) {
      Document = {
        DocumentType: DocumentType.Tms,
        Attachment: selectedFile.files[0],
        Description: selectedFile.files[0] ? selectedFile.files[0].name : '',
        FileSize: selectedFile.files[0].size,
        Status: Status.Active,
        ...commonInput,
      }
    } else {
      DocumentID = state?.TimeOffListing?.Document?.DocumentID
    }

    if (TimeOffReasonByTLA.length > 0) {
      if (data?.ToReasonID) {
        setTOReason(data?.ToReasonID)
        const commonCreatedData = {
          CreatedDT: new Date(),
          CreatedBy: loggedInEmployee?.employeeID,
          ModifiedDT: new Date(),
          ModifiedBy: loggedInEmployee?.employeeID,
          Status: Status.Active,
        }
        const commonUpdatedData = {
          CreatedDT: editData?.TimeOffListing?.CreatedDT,
          CreatedBy: editData?.TimeOffListing?.CreatedBy,
          ModifiedDT: new Date(),
          ModifiedBy: loggedInEmployee?.employeeID,
        }
        if (
          TimeOffListingByEmployee &&
          TimeOffListingByEmployee.filter(
            x => x.TimeOffID == editData?.TimeOffListing?.TimeOffID
          ).length > 0
        ) {
          TimeOffInsert({
            variables: {
              input: {
                ...commonUpdatedData,
                ApproverID: EmployeeDetails?.ReportTo,
                TimeOffID: editData?.TimeOffListing?.TimeOffID,
                EmployeeID: loggedInEmployee.employeeID,
                Document: Document,
                DocumentID: DocumentID,
                Remark: data?.Remark,
                WorkDate: data?.WorkDate,
                StartTime: selectedStartDate,
                EndTime: selectedExpiryDate,
                ToReasonID: data?.ToReasonID,
                TimeOffStatus: TimeOffStatus.Draft,
                VisitingPlace: data?.VisitingPlace,
                TravelFrom: data?.TravelFrom,
                TravelTo: data?.TravelTo,
                CompanyID: loggedInEmployee?.companyID,
                Duration: timeElapsedByMin(
                  new Date(selectedExpiryDate),
                  new Date(selectedStartDate)
                ),
              },
            },
          })
        } else {
          TimeOffInsert({
            variables: {
              input: {
                ...commonCreatedData,
                ApproverID: EmployeeDetails?.ReportTo,
                EmployeeID: loggedInEmployee.employeeID,
                ToReasonID: data?.ToReasonID,
                WorkDate: data?.WorkDate,
                StartTime: selectedStartDate,
                EndTime: selectedExpiryDate,
                Document: Document,
                DocumentID: DocumentID,
                Remark: data?.Remark,
                TimeOffStatus: TimeOffStatus.Draft,
                VisitingPlace: data?.VisitingPlace,
                TravelFrom: data?.TravelFrom,
                TravelTo: data?.TravelTo,
                CompanyID: loggedInEmployee?.companyID,
                Duration: timeElapsedByMin(
                  new Date(selectedExpiryDate),
                  new Date(selectedStartDate)
                ),
              },
            },
          })
        }
      } else {
        setError(`ToReasonID`, {
          type: 'required',
          message: 'Reason is required',
        })
      }
    } else {
      const commonCreatedData = {
        CreatedDT: new Date(),
        CreatedBy: loggedInEmployee?.employeeID,
        ModifiedDT: new Date(),
        ModifiedBy: loggedInEmployee?.employeeID,
        Status: Status.Active,
      }
      const commonUpdatedData = {
        ModifiedDT: new Date(),
        ModifiedBy: loggedInEmployee?.employeeID,
      }
      if (
        TimeOffListingByEmployee &&
        TimeOffListingByEmployee.filter(
          x => x.TimeOffID == editData?.TimeOffListing?.TimeOffID
        ).length > 0
      ) {
        TimeOffInsert({
          variables: {
            input: {
              ...commonUpdatedData,
              ApproverID: EmployeeDetails?.ReportTo,
              TimeOffID: editData?.TimeOffListing?.TimeOffID,
              EmployeeID: loggedInEmployee.employeeID,
              Remark: data?.Remark,
              WorkDate: data?.WorkDate,
              Document: Document,
              DocumentID: DocumentID,
              StartTime: selectedStartDate,
              EndTime: selectedExpiryDate,
              ToReasonID: data?.ToReasonID,
              TimeOffStatus: TimeOffStatus.Draft,
              VisitingPlace: data?.VisitingPlace,
              TravelFrom: data?.TravelFrom,
              TravelTo: data?.TravelTo,
              CompanyID: loggedInEmployee?.companyID,
              Duration: timeElapsedByMin(
                new Date(selectedExpiryDate),
                new Date(selectedStartDate)
              ),
            },
          },
        })
      } else {
        TimeOffInsert({
          variables: {
            input: {
              ...commonCreatedData,
              ApproverID: EmployeeDetails?.ReportTo,
              EmployeeID: loggedInEmployee.employeeID,
              ToReasonID: data?.ToReasonID,
              WorkDate: data?.WorkDate,
              StartTime: selectedStartDate,
              EndTime: selectedExpiryDate,
              Document: Document,
              DocumentID: DocumentID,
              Remark: data?.Remark,
              TimeOffStatus: TimeOffStatus.Draft,
              VisitingPlace: data?.VisitingPlace,
              TravelFrom: data?.TravelFrom,
              TravelTo: data?.TravelTo,
              CompanyID: loggedInEmployee?.companyID,
              Duration: timeElapsedByMin(
                new Date(selectedExpiryDate),
                new Date(selectedStartDate)
              ),
            },
          },
        })
      }
    }
  }

  if (
    TimeOffListingByEmployee &&
    TimeOffListingByEmployee.filter(
      x => x.TimeOffID == editData?.TimeOffListing?.TimeOffID
    ).length > 0
  ) {
    formMode = 'Edit'
  }

  let toList = TimeOffListingByEmployee.filter(
    x => x.TimeOffID == editData?.TimeOffListing?.TimeOffID
  )

  useEffect(() => {
    if (formMode == 'Edit') {
      setValue('EmployeeID', toList[0]?.EmployeeID)
      setValue('TimeOffID', toList[0]?.TimeOffID)
      setValue('Remark', toList[0]?.Remark)
      setValue('WorkDate', toList[0]?.WorkDate)
      setValue('StartTime', toList[0]?.StartTime)
      setValue('EndTime', toList[0]?.EndTime)
      setValue('ToReasonID', toList[0]?.ToReasonID)
      setValue('TimeOffStatus', toList[0]?.TimeOffStatus)
      setValue('VisitingPlace', toList[0]?.VisitingPlace)
      setValue('TravelFrom', toList[0]?.TravelFrom)
      setValue('TravelTo', toList[0]?.TravelTo)
    }
  }, [formMode])

  const onSubmit = (data: TimeOffForm) => {
    let Document: any = null
    let DocumentID: any = null
    setDisableConfirm(true)
    const commonInput = {
      CreatedDT: new Date(),
      CreatedBy: loggedInEmployee?.employeeID,
      ModifiedDT: new Date(),
      ModifiedBy: loggedInEmployee?.employeeID,
    }
    if (selectedFile.files.length > 0) {
      Document = {
        DocumentType: DocumentType.Tms,
        Attachment: selectedFile.files[0],
        Description: selectedFile.files[0] ? selectedFile.files[0].name : '',
        FileSize: selectedFile.files[0].size,
        Status: Status.Active,
        ...commonInput,
      }
    } else {
      DocumentID = state?.TimeOffListing?.Document?.DocumentID
    }
    if (TimeOffReasonByTLA.length > 0) {
      if (data?.ToReasonID) {
        const commonCreatedData = {
          CreatedDT: new Date(),
          CreatedBy: loggedInEmployee?.employeeID,
          ModifiedDT: new Date(),
          ModifiedBy: loggedInEmployee?.employeeID,
          Status: Status.Active,
        }
        const commonUpdatedData = {
          CreatedDT: editData?.TimeOffListing?.CreatedDT,
          CreatedBy: editData?.TimeOffListing?.CreatedBy,
          ModifiedDT: new Date(),
          ModifiedBy: loggedInEmployee?.employeeID,
        }

        if (
          TimeOffListingByEmployee &&
          TimeOffListingByEmployee.filter(
            x => x.TimeOffID == editData?.TimeOffListing?.TimeOffID
          ).length > 0
        ) {
          TimeOffInsert({
            variables: {
              input: {
                ...commonUpdatedData,
                ApproverID: EmployeeDetails?.ReportTo,
                TimeOffID: editData?.TimeOffListing?.TimeOffID,
                EmployeeID: loggedInEmployee?.employeeID,
                Remark: data?.Remark,
                WorkDate: data?.WorkDate,
                Document: Document,
                DocumentID: DocumentID,
                StartTime: selectedStartDate,
                EndTime: selectedExpiryDate,
                ToReasonID: data?.ToReasonID,
                TimeOffStatus: TimeOffStatus.Pending,
                VisitingPlace: data?.VisitingPlace,
                TravelFrom: data?.TravelFrom,
                TravelTo: data?.TravelTo,
                CompanyID: loggedInEmployee?.companyID,
                Duration: timeElapsedByMin(
                  new Date(selectedExpiryDate),
                  new Date(selectedStartDate)
                ),
              },
            },
            refetchQueries: refQuery(data),
          })
        } else {
          TimeOffInsert({
            variables: {
              input: {
                ...commonCreatedData,
                ApproverID: EmployeeDetails?.ReportTo,
                EmployeeID: loggedInEmployee.employeeID,
                ToReasonID: data?.ToReasonID,
                WorkDate: data?.WorkDate,
                StartTime: selectedStartDate,
                EndTime: selectedExpiryDate,
                Remark: data?.Remark,
                Document: Document,
                DocumentID: DocumentID,
                TimeOffStatus: TimeOffStatus.Pending,
                VisitingPlace: data?.VisitingPlace,
                TravelFrom: data?.TravelFrom,
                TravelTo: data?.TravelTo,
                CompanyID: loggedInEmployee?.companyID,
                Duration: timeElapsedByMin(
                  new Date(selectedExpiryDate),
                  new Date(selectedStartDate)
                ),
              },
            },
            refetchQueries: refQuery(data),
          })
        }
      } else {
        setError(`ToReasonID`, {
          type: 'required',
          message: 'Reason is required',
        })
      }
    } else {
      const commonCreatedData = {
        CreatedDT: new Date(),
        CreatedBy: loggedInEmployee?.employeeID,
        ModifiedDT: new Date(),
        ModifiedBy: loggedInEmployee?.employeeID,
        Status: Status.Active,
      }
      const commonUpdatedData = {
        CreatedDT: editData?.TimeOffListing?.CreatedDT,
        CreatedBy: editData?.TimeOffListing?.CreatedBy,
        ModifiedDT: new Date(),
        ModifiedBy: loggedInEmployee?.employeeID,
      }

      if (
        TimeOffListingByEmployee &&
        TimeOffListingByEmployee.filter(
          x => x.TimeOffID == editData?.TimeOffListing?.TimeOffID
        ).length > 0
      ) {
        TimeOffInsert({
          variables: {
            input: {
              ...commonUpdatedData,
              ApproverID: EmployeeDetails?.ReportTo,
              TimeOffID: editData?.TimeOffListing?.TimeOffID,
              EmployeeID: loggedInEmployee?.employeeID,
              Remark: data?.Remark,
              WorkDate: data?.WorkDate,
              StartTime: selectedStartDate,
              EndTime: selectedExpiryDate,
              Document: Document,
              DocumentID: DocumentID,
              ToReasonID: data?.ToReasonID,
              TimeOffStatus: TimeOffStatus.Pending,
              VisitingPlace: data?.VisitingPlace,
              TravelFrom: data?.TravelFrom,
              TravelTo: data?.TravelTo,
              CompanyID: loggedInEmployee?.companyID,
              Duration: timeElapsedByMin(
                new Date(selectedExpiryDate),
                new Date(selectedStartDate)
              ),
            },
          },
          refetchQueries: refQuery(data),
        })
      } else {
        TimeOffInsert({
          variables: {
            input: {
              ...commonCreatedData,
              ApproverID: EmployeeDetails?.ReportTo,
              EmployeeID: loggedInEmployee.employeeID,
              ToReasonID: data?.ToReasonID,
              WorkDate: data?.WorkDate,
              StartTime: selectedStartDate,
              EndTime: selectedExpiryDate,
              Remark: data?.Remark,
              Document: Document,
              DocumentID: DocumentID,
              TimeOffStatus: TimeOffStatus.Pending,
              VisitingPlace: data?.VisitingPlace,
              TravelFrom: data?.TravelFrom,
              TravelTo: data?.TravelTo,
              CompanyID: loggedInEmployee?.companyID,
              Duration: timeElapsedByMin(
                new Date(selectedExpiryDate),
                new Date(selectedStartDate)
              ),
            },
          },
          refetchQueries: refQuery(data),
        })
      }
    }
  }

  return (
    <>
      {toLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        smTitle={'Employee Super App'}
        title={
          EmployeeDetails?.Salutation != ''
            ? `${EmployeeDetails?.Salutation} ${EmployeeDetails?.Contact?.FullName}`
            : `${EmployeeDetails?.Contact?.FullName}`
        }
        onClick={() =>
          history.push('/Employee/tms/timeofflist', {
            filterSearch: state?.filterSearch,
          })
        }
        routeSegments={[
          { name: 'My Time Off' },
          { name: 'My Time Off' },
          { name: 'My Time Off' },
          { name: 'My Time Off', current: true },
        ]}
        rightRouteSegments={[
          { name: `${formMode == 'Edit' ? 'Edit' : 'New'}`, current: true },
        ]}
      />

      <form id="submit-form">
        <ContentWrapper footer>
          <CardContents>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={KeyboardDatePicker}
                name="WorkDate"
                label="Work Date"
                required
                autoOk={true}
                control={control}
                onChange={date => {
                  setValue('WorkDate', date)
                  return { value: date } //important to update the controller value after change else state is updated and the controller will not render
                }}
                defaultValue={null}
                format="dd MMM yyyy"
                value={watch('WorkDate')}
                allowKeyboardControl
                margin="normal"
                inputProps={{ readOnly: true }}
                ref={register}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                error={errors?.WorkDate ? true : false}
                helperText={errors?.WorkDate?.message}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                required
                value={selectedStartDate}
                margin="normal"
                name="StartTime"
                label="Start Time"
                onChange={date => {
                  handleStartDateChange(date)
                  if (formMode === 'New') {
                    handleExpiryDateChange(date)
                  }
                }}
                format="dd MMM hh:mm a"
                fullWidth
                id="time-picker"
                minDate={getValues('WorkDate')}
                maxDate={getValues('WorkDate')}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                inputProps={{ readOnly: true }}
                className="left"
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                value={selectedExpiryDate}
                margin="normal"
                name="EndTime"
                label="End Time"
                onChange={date => {
                  handleExpiryDateChange(date)
                }}
                format="dd MMM hh:mm a"
                fullWidth
                minDate={getValues('WorkDate')}
                maxDate={getValues('WorkDate')}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                id="time-picker"
                inputProps={{ readOnly: true }}
                className="right"
              />
            </MuiPickersUtilsProvider>
            Duration<br></br>
            {`${timeElapsed(selectedStartDate, selectedExpiryDate)} `}
            <br></br>
            <Controller
              as={TextField}
              id="standard-basic"
              defaultValue=""
              name="VisitingPlace"
              label="Visiting Place"
              fullWidth
              control={control}
              error={errors?.VisitingPlace ? true : false}
              helperText={errors?.VisitingPlace?.message}
            />
            <Controller
              as={TextField}
              id="standard-basic"
              defaultValue=""
              name="TravelFrom"
              label="Travel From"
              className="left"
              fullWidth
              control={control}
              error={errors?.TravelFrom ? true : false}
              helperText={errors?.TravelFrom?.message}
            />
            <Controller
              as={TextField}
              id="standard-basic"
              defaultValue=""
              name="TravelTo"
              label="Travel To"
              className="right"
              fullWidth
              control={control}
              error={errors?.TravelTo ? true : false}
              helperText={errors?.TravelTo?.message}
            />
            <Controller
              as={
                <TextField select>
                  {TimeOffReasonByTLA?.map((option, index) => (
                    <MenuItem
                      key={option?.TimeOffReasonID}
                      value={option?.TimeOffReasonID}
                    >
                      {option?.Name}
                    </MenuItem>
                  ))}
                </TextField>
              }
              style={{ marginTop: '12px' }}
              className="m-0"
              id="standard-basic"
              label="Time Off Reason"
              fullWidth
              required
              name="ToReasonID"
              defaultValue={TOReason}
              control={control}
              error={errors?.ToReasonID}
              helperText={errors?.ToReasonID ? 'Reason is required!' : null}
            />
            <Controller
              as={TextField}
              className="m-0"
              fullWidth
              defaultValue=""
              name="Remark"
              label="Remark"
              control={control}
            />
            {true ? (
              <>
                <span className="color-red">
                  {errors?.Remark && errors?.Remark?.message}
                </span>
              </>
            ) : null}
            <InputUploadAllType
              value={selectedFileName}
              label="Attachment"
              handleUpload={e => onChangeFile(e)}
              className="p-b-20"
            />
            {imagePreview !== null ? (
              <UploadPreview
                src={imagePreview}
                onClick={() => {
                  setSelectedFile({ files: [] })
                  setSelectedFileName('')
                  setImagePreview(null)
                }}
                remove
              />
            ) : null}
          </CardContents>
        </ContentWrapper>
      </form>

      <SnackBarMsg open={openSnackBar} message={snackBarMessage} />

                          {
               user?.superUser ||
               GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                 HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuSubmit
               ) ? (
                <Footer
                options={[
                  {
                    name: 'Save as draft',
                    onClick: handleSubmit(onDraft),
                    color: 'primary',
                    mode: 'edit',
                    disabled: disableConfirm,
                  },
                  {
                    name: 'Submit',
                    onClick: handleSubmit(onSubmit),
                    color: 'primary',
                    disabled: disableConfirm,
                  },
                ]}
              />
               ) : (
                <Footer
                options={[
                  {
                    name: 'Save as draft',
                    onClick: handleSubmit(onDraft),
                    color: 'primary',
                    mode: 'edit',
                    disabled: disableConfirm,
                  },
             
                ]}
              />
               )
            }  

    </>
  )
}
