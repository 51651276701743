import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { Breadcrumbs } from '@material-ui/core'

interface IHeader {
  CompanyName: string
}
export const FullHeader = (props: IHeader) => {
  return (
    <AppBar className="full-header" position={'fixed'}>
      <Toolbar>
        <div className="domain-container">
          <div className="domain-session">
            <div className="authorizer" style={{ lineHeight: '0.7rem' }}>
              {'Project Timesheet'}
            </div>
            <div className="domain">{props?.CompanyName}</div>
          </div>
        </div>

        <div style={{ width: '100%', display: 'flex' }}>
          <Breadcrumbs
            className="breadcrumbs"
            separator="/"
            aria-label="breadcrumb"
          >
            <span className="breadcrumb-title">
              {'Project Timesheet Approval'}
            </span>
          </Breadcrumbs>
        </div>
      </Toolbar>
    </AppBar>
  )
}
