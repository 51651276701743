import React from 'react'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import { timeElapsed } from 'containers/helper/commonFormats'
import { lowerCaseAllWordsExceptFirstLetters } from 'containers/helper/stringConverter'
import { useHistory, useLocation } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator'
import clsx from 'clsx'

const useStyles = makeStyles({
  flexContainer: {
    display: 'flex',
  },

  flexCol: {
    flexDirection: 'column',
  },

  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const Detail = ({
  className,
  label,
  value,
  children,
  placement = 'full',
}: {
  className?: string
  placement?: 'left' | 'right' | 'full'
  label: string
  value?: string
  children?: React.ReactNode
}) => {
  return (
    <div className={clsx('content-wrap', placement, className)}>
      <span className="desc" style={{ color: 'grey' }}>
        {label}
      </span>
      <div className="mdDesc">{value || children}</div>
    </div>
  )
}

export const TodoOvertimeDetails = (props: any) => {
  const dateFormat = require('dateformat')
  const history = useHistory()
  const classes = useStyles()

  const { state }: any = useLocation()
  const loggedInEmployee = JSON.parse(localStorage.getItem('loggedInEmployee'))

  console.log('State todo overtime: ', state)
  return (
    <>
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push('/home/todo/Overtime', {
            filterSearch: state?.filterSearch,
          })
        }
        // title={`${loggedInEmployee?.Salutation} ${loggedInEmployee?.name}`}
        title={
          state?.Company?.CompanyName || ''
          // loggedInEmployee?.Salutation != ''
          //   ? `${loggedInEmployee?.Salutation} ${loggedInEmployee?.name}`
          //   : `${loggedInEmployee?.name}`
        }
        smTitle={`Employee Super App`}
        routeSegments={[
          { name: 'Employee App' },
          { name: 'Employee App' },
          { name: 'Overtime Application', current: true },
        ]}
        rightRouteSegments={[{ name: 'Details', current: true }]}
      />
      <DynamicSubHeader
        title={
          <>
            <div className={classes.flexContainer}>
              <span className="flex-space" style={{ display: 'flex' }}>
                <span className="xsTitle">
                  {state?.Employee?.Contact?.FullName}
                  <TextSeparator />
                  <span className="fs-12">{state?.Employee?.EmployeeNo}</span>
                </span>
              </span>
              <span
                className="desc"
                style={{
                  color: `${'orange'}`,
                  font: 'bold',
                }}
              >
                {dateFormat(state?.ModifiedDT, 'dd mmm yyyy')}
              </span>
            </div>
          </>
        }
      />

      <ContentWrapper singleDynamicInfo>
        <CardContents>
          <Detail
            label="Work Date"
            value={dateFormat(state?.WorkDate, 'dd/mm/yyyy')}
          />
          <Detail
            label="Start Time"
            placement="left"
            value={dateFormat(state?.StartTime, 'h:MM TT')}
          />
          <Detail
            label="End Time"
            placement="right"
            value={dateFormat(state?.EndTime, 'h:MM TT')}
          />
          <Detail
            label="Duration"
            value={timeElapsed(state?.EndTime, state?.StartTime)}
          />
          <Detail label="Overtime Reason" value={state?.otReason?.Name} />
          <Detail label="Remark" value={state?.Remark || 'No Records'} />
          <Detail
            label="Status"
            value={lowerCaseAllWordsExceptFirstLetters(state?.OvertimeStatus)}
          />

          {state?.Documents?.length > 0 && (
            <Detail label="Attachment">
              <div className={classes.flexContainer}>
                {state?.Documents?.map((doc: any) => (
                  <div
                    className={clsx(
                      classes.flexContainer,
                      classes.flexCol,
                      classes.center
                    )}
                  >
                    <UploadPreview src={doc.DocumentFile} onClick={() => {}} />
                    <div className="desc">{doc.Description}</div>
                  </div>
                ))}
              </div>
            </Detail>
          )}
        </CardContents>
      </ContentWrapper>
    </>
  )
}
