import React, { useState } from 'react'
import { useParams } from 'react-router'
import { ProjectTimeSheetSubmissionTable } from './ProjectTimeSheetSubmissionTable'
import {
  WorkflowLogStatus,
  useProjectTimesheetApprovalListingQuery,
  useSubmitProjectTimesheetApprovalMutation,
} from 'generated/graphql'
import { FullHeader } from 'components/ExternalComponent/FullHeader'
import { FullDynamicHeader } from 'components/ExternalComponent/FullDynamicHeader'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { TextField } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import dateFormat from 'dateformat'
import './submissiontable.scss'

export const ExternalSummaryTimesheet = (props: any) => {
  const { token }: any = useParams()
  const [rejectDialog, setRejectDialog] = useState(false)
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [respondType, setRespondType] = useState(null)
  const [CompletedStatus, setCompletedStatus] = useState(false)
  const requiredField = 'This field is required.'
  const [warningDialog, setWarningDialog] = useState(false)

  const { handleSubmit, control, errors } = useForm({
    shouldUnregister: true,
    mode: 'onBlur',
  })

  const {
    data: { ProjectTimesheetApprovalListing } = {
      ProjectTimesheetApprovalListing: [],
    },
    loading: ProjectTimesheetApprovalListingLoading,
  } = useProjectTimesheetApprovalListingQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      token: token,
    },
  })

  const [
    SubmitProjectTimesheetApproval,
    { loading: SubmitProjectTimesheetApprovalLoading },
  ] = useSubmitProjectTimesheetApprovalMutation({
    onCompleted: data => {
      if (data.SubmitProjectTimesheetApproval) {
        setConfirmationDialog(true)
        setCompletedStatus(true)
      } else {
        setWarningDialog(true)
      }
    },
    fetchPolicy: 'no-cache',
  })

  const onSubmit = (data, type) => {
    // console.log('data', data)

    if (type === 'Approve') {
      setRespondType('approved')
    } else {
      setRespondType('rejected')
    }
    SubmitProjectTimesheetApproval({
      variables: {
        token: token,
        StatusToChange:
          type === 'Approve'
            ? WorkflowLogStatus.Approved
            : WorkflowLogStatus.Rejected,
        Remarks: type === 'Reject' ? data?.Remark : null,
      },
    })
  }

  return (
    <>
      {!CompletedStatus && !warningDialog ? (
        <>
          {' '}
          {ProjectTimesheetApprovalListingLoading ? (
            <Loading />
          ) : (
            <>
              {ProjectTimesheetApprovalListing?.Status ===
              WorkflowLogStatus.Submitted ? (
                <>
                  <FullHeader
                    CompanyName={
                      ProjectTimesheetApprovalListing?.Employee?.CompanyName
                    }
                  />
                  <FullDynamicHeader
                    title={`${ProjectTimesheetApprovalListing?.Employee?.FullName} | ${ProjectTimesheetApprovalListing?.Employee?.NRIC}`}
                    rightText={`Approver: ${ProjectTimesheetApprovalListing?.Approver?.FullName}`}
                    infoLine={
                      <>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <span className="extra-row flex-space desc">
                            <span style={{ fontSize: '11px', fontWeight: 600 }}>
                              {dateFormat(
                                new Date(
                                  ProjectTimesheetApprovalListing?.PeriodYearMonth
                                ),
                                'mmm yyyy'
                              )}{' '}
                              |{' '}
                              {`${dateFormat(
                                new Date(
                                  new Date(
                                    ProjectTimesheetApprovalListing?.PeriodYearMonth
                                  ).getFullYear(),
                                  new Date(
                                    ProjectTimesheetApprovalListing?.PeriodYearMonth
                                  ).getMonth(),
                                  1
                                ),
                                'dd mmm yyyy'
                              )} to ${dateFormat(
                                new Date(
                                  new Date(
                                    ProjectTimesheetApprovalListing?.PeriodYearMonth
                                  ).getFullYear(),
                                  new Date(
                                    ProjectTimesheetApprovalListing?.PeriodYearMonth
                                  ).getMonth() + 1,
                                  0
                                ),
                                'dd mmm yyyy'
                              )}`}
                            </span>
                          </span>

                          <span
                            style={{ fontSize: '11px', fontWeight: 600 }}
                          >{`${ProjectTimesheetApprovalListing?.Approver?.Department} | ${ProjectTimesheetApprovalListing?.Approver?.ClientName}`}</span>
                        </div>
                      </>
                    }
                  />
                  <div className={`external-subheader-table`}>
                    <ProjectTimeSheetSubmissionTable
                      SubmissionListing={
                        ProjectTimesheetApprovalListing?.DailyEntry
                      }
                    />
                  </div>
                  <Footer
                    externalDisplay
                    options={[
                      {
                        name: 'Reject',
                        onClick: () => {
                          setRejectDialog(true)
                        },
                        color: 'secondary',
                      },
                      {
                        name: 'Approve',
                        onClick: () => {
                          handleSubmit(data => onSubmit(data, 'Approve'))()
                        },
                        color: 'primary',
                      },
                    ]}
                  />
                  <CommonDialog
                    fullWidth={true}
                    open={rejectDialog}
                    // onClose={() => setRejectDialog(false)}
                    sections={{
                      header: {
                        dynamic: (
                          <div className="">
                            <div className="dialog-dynamic-content">
                              <div className="session">
                                <div className="flex session">
                                  <div className="title c-darkblue flex-space">
                                    Reject Confirmation
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                      },
                      body: () => (
                        <>
                          <div style={{ paddingBottom: '5px' }}>
                            Please key in a remark for the rejection
                          </div>

                          <Controller
                            as={TextField}
                            fullWidth
                            required
                            defaultValue={''}
                            control={control}
                            label="Remark"
                            name="Remark"
                            rules={{
                              required: { value: true, message: requiredField },
                            }}
                            helperText={
                              errors?.Remark?.type === 'required' &&
                              errors?.Remark?.message
                            }
                            error={
                              errors?.Remark &&
                              errors?.Remark?.type === 'required'
                                ? true
                                : false
                            }
                          />
                        </>
                      ),
                      footer: {
                        actions: [
                          {
                            displayText: 'Cancel',
                            props: {
                              onClick: () => setRejectDialog(false),
                              variant: 'contained',
                              color: 'primary',
                            },
                          },
                          {
                            displayText: 'Confirm',
                            props: {
                              onClick: () => {
                                handleSubmit(data => onSubmit(data, 'Reject'))()
                              },
                              variant: 'contained',
                              color: 'primary',
                            },
                          },
                        ],
                      },
                    }}
                  />
                </>
              ) : (
                <>
                  <CommonDialog
                    fullWidth={true}
                    open={true}
                    sections={{
                      header: {
                        dynamic: (
                          <div className="">
                            <div className="dialog-dynamic-content">
                              <div className="session">
                                <div className="flex session">
                                  <div className="title c-darkblue flex-space">
                                    Project Timesheet Approval
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                      },
                      body: () => (
                        <>
                          This project timesheet has been{' '}
                          {ProjectTimesheetApprovalListing?.Status}.
                        </>
                      ),
                      footer: {
                        actions: [
                          {
                            displayText: 'Confirm',
                            props: {
                              onClick: () => {
                                window.close()
                              },
                              variant: 'contained',
                              color: 'primary',
                            },
                          },
                        ],
                      },
                    }}
                  />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {CompletedStatus ? (
            <>
              {' '}
              <CommonDialog
                fullWidth={true}
                open={confirmationDialog}
                onClose={() => setConfirmationDialog(false)}
                sections={{
                  header: {
                    dynamic: (
                      <div className="">
                        <div className="dialog-dynamic-content">
                          <div className="session">
                            <div className="flex session">
                              <div className="title c-darkblue flex-space">
                                Approved Confirmation
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                  body: () => (
                    <>
                      {respondType === 'approved' ? (
                        <div>Timesheet is approved successfully.</div>
                      ) : (
                        <div>Timesheet is rejected.</div>
                      )}
                    </>
                  ),
                  footer: {
                    actions: [
                      {
                        displayText: 'Confirm',
                        props: {
                          onClick: () => {
                            window.close()
                          },
                          variant: 'contained',
                          color: 'primary',
                        },
                      },
                    ],
                  },
                }}
              />
            </>
          ) : (
            <>
              <CommonDialog
                fullWidth={true}
                open={warningDialog}
                onClose={() => setWarningDialog(false)}
                sections={{
                  header: {
                    dynamic: (
                      <div className="">
                        <div className="dialog-dynamic-content">
                          <div className="session">
                            <div className="flex session">
                              <div className="title c-darkblue flex-space">
                                Failed
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                  body: () => (
                    <>
                      Failed to submit project timesheet, please contact our
                      support team at hrxsupport@ifca.com.my.
                    </>
                  ),
                  footer: {
                    actions: [
                      {
                        displayText: 'Confirm',
                        props: {
                          onClick: () => {
                            window.close()
                          },
                          variant: 'contained',
                          color: 'primary',
                        },
                      },
                    ],
                  },
                }}
              />
            </>
          )}
        </>
      )}
    </>
  )
}
