import React, { lazy } from 'react'

const EmployeeBasicInfo = lazy(() =>
  import('./Personnel/EmployeeBasicInfo').then(module => ({
    default: module.EmployeeBasicInfo,
  }))
)

const EmployeeBasicInfoReport = lazy(() =>
  import('./Personnel/EmployeeBasicInfoReport').then(module => ({
    default: module.EmployeeBasicInfoReport,
  }))
)

const EmployeeConfirmation = lazy(() =>
  import('./Personnel/EmployeeConfirmation').then(module => ({
    default: module.EmployeeConfirmation,
  }))
)

const EmployeeConfirmationReport = lazy(() =>
  import('./Personnel/EmployeeConfirmationReport').then(module => ({
    default: module.EmployeeConfirmationReport,
  }))
)

const EmployeeDependent = lazy(() =>
  import('./Personnel/EmployeeDependent').then(module => ({
    default: module.EmployeeDependent,
  }))
)

const EmployeeDependentReport = lazy(() =>
  import('./Personnel/EmployeeDependentReport').then(module => ({
    default: module.EmployeeDependentReport,
  }))
)

const EmployeeEducation = lazy(() =>
  import('./Personnel/EmployeeEducation').then(module => ({
    default: module.EmployeeEducation,
  }))
)


const EmployeeEducationReport = lazy(() =>
  import('./Personnel/EmployeeEducationReport').then(module => ({
    default: module.EmployeeEducationReport,
  }))
)

const EmployeeNewJoin = lazy(() =>
  import('./Personnel/EmployeeNewJoin').then(module => ({
    default: module.EmployeeNewJoin,
  }))
)

const EmployeeNewJoinReport = lazy(() =>
  import('./Personnel/EmployeeNewJoinReport').then(module => ({
    default: module.EmployeeNewJoinReport,
  }))
)

const EmployeeResignation = lazy(() =>
  import('./Personnel/EmployeeResignation').then(module => ({
    default: module.EmployeeResignation,
  }))
)

const EmployeeResignationReport = lazy(() =>
  import('./Personnel/EmployeeResignationReport').then(module => ({
    default: module.EmployeeResignationReport,
  }))
)

const EmployeeMasterExcelReport = lazy(() =>
  import(
    './Personnel/EmployeeMasterExcel/EmployeeMasterExcelReport'
  ).then(module => ({
    default: module.EmployeeMasterExcelReport,
  }))
)

const EmployeeServiceMovement = lazy(() =>
  import('./Personnel/EmployeeServiceMovement/EmployeeServiceMovement').then(module => ({
    default: module.EmployeeServiceMovement,
  }))
)

const EmployeeServiceMovementReport = lazy(() =>
  import('./Personnel/EmployeeServiceMovement/EmployeeServiceMovementReport').then(module => ({
    default: module.EmployeeServiceMovementReport,
  }))
)

const EmployeeServiceSalaryMovement = lazy(() =>
  import('./Personnel/EmployeeServiceSalaryMovement/EmployeeServiceSalaryMovement').then(module => ({
    default: module.EmployeeServiceSalaryMovement,
  }))
)

const EmployeeServiceSalaryMovementReport = lazy(() =>
  import('./Personnel/EmployeeServiceSalaryMovement/EmployeeServiceSalaryMovementReport').then(module => ({
    default: module.EmployeeServiceSalaryMovementReport,
  }))
)

const BankListing = lazy(() =>
  import('./Payroll/Bank/BankListing').then(module => ({
    default: module.BankListing,
  }))
)

const BankPayoutListing = lazy(() =>
  import('./Payroll/Bank/BankPayoutListing/BankPayoutListing').then(module => ({
    default: module.BankPayoutListing,
  }))
)

const BankPayoutListingReport = lazy(() =>
  import('./Payroll/Bank/BankPayoutListing/BankPayoutListingReport').then(
    module => ({
      default: module.BankPayoutListingReport,
    })
  )
)

const CashChequePayoutListing = lazy(() =>
  import('./Payroll/Bank/CashChequePayoutListing/CashChequePayoutListing').then(
    module => ({
      default: module.CashChequePayoutListing,
    })
  )
)

const CashChequePayoutListingReport = lazy(() =>
  import(
    './Payroll/Bank/CashChequePayoutListing/CashChequePayoutListingReport'
  ).then(module => ({
    default: module.CashChequePayoutListingReport,
  }))
)

const PayrollListing = lazy(() =>
  import('./Payroll/PayrollListing/PayrollListing').then(module => ({
    default: module.PayrollListing,
  }))
)
const PayrollDetailListingExcelExport = lazy(() =>
  import('./Payroll/PayrollListing/PayrollDetailListingExcelExport/PayrollDetailListingExcelExport').then(
    module => ({
      default: module.PayrollDetailListingExcelExport,
    })
  )
)

const EmployeePayHistory = lazy(() =>
  import('./Payroll/PayrollListing/EmployeePayHistory/EmployeePayHistory').then(module => ({
    default: module.EmployeePayHistory,
  }))
)

const EmployeePayHistoryReport = lazy(() =>
  import('./Payroll/PayrollListing/EmployeePayHistory/EmployeePayHistoryReport').then(module => ({
    default: module.EmployeePayHistoryReport,
  }))
)

const EmployeeSalary = lazy(() =>
  import('./Payroll/PayrollListing/EmployeeSalary/EmployeeSalary').then(module => ({
    default: module.EmployeeSalary,
  }))
)

const EmployeeSalaryReport = lazy(() =>
  import('./Payroll/PayrollListing/EmployeeSalary/EmployeeSalaryReport').then(module => ({
    default: module.EmployeeSalaryReport,
  }))
)


const HistoryTransummary = lazy(() =>
  import('./Payroll/PayrollListing/HistoryTransummary').then(module => ({
    default: module.HistoryTransummary,
  }))
)

const HistoryTransSummaryByOrgUnit = lazy(() =>
  import('./Payroll/PayrollListing/HistoryTransSummaryByOrgUnit/HistoryTransSummaryByOrgUnit').then(module => ({
    default: module.HistoryTransSummaryByOrgUnit,
  }))
)

const HistoryTransSummaryByOrgUnitReport = lazy(() =>
  import('./Payroll/PayrollListing/HistoryTransSummaryByOrgUnit/HistoryTransSummaryByOrgUnitReport').then(module => ({
    default: module.HistoryTransSummaryByOrgUnitReport,
  }))
)

const PayrollDetail = lazy(() =>
  import('./Payroll/PayrollListing/PayrollDetail').then(module => ({
    default: module.PayrollDetail,
  }))
)

const PayrollDetailListing = lazy(() =>
  import('./Payroll/PayrollListing/PayrollDetailListing/PayrollDetailListing').then(module => ({
    default: module.PayrollDetailListing,
  }))
)

const PayrollDetailListingReport = lazy(() =>
  import('./Payroll/PayrollListing/PayrollDetailListing/PayrollDetailListingReport').then(module => ({
    default: module.PayrollDetailListingReport,
  }))
)
const PayrollTransactionDetailsList = lazy(() =>
  import('./Payroll/PayrollListing/PayrollTransactionDetailsList/PayrollTransactionDetailsList').then(
    module => ({
      default: module.PayrollTransactionDetailsList,
    })
  )
)

const PayrollTransactionDetailsListReport = lazy(() =>
  import('./Payroll/PayrollListing/PayrollTransactionDetailsList/PayrollTransactionDetailsListReport').then(
    module => ({
      default: module.PayrollTransactionDetailsListReport,
    })
  )
)

const PayrollTransactionDetailsListing = lazy(() =>
  import('./Payroll/PayrollListing/PayrollTransactionDetailsListing/PayrollTransactionDetailsListing').then(
    module => ({
      default: module.PayrollTransactionDetailsListing,
    })
  )
)

const PayrollTransactionDetailsListingReport = lazy(() =>
  import('./Payroll/PayrollListing/PayrollTransactionDetailsListing/PayrollTransactionDetailsListingReport').then(
    module => ({
      default: module.PayrollTransactionDetailsListingReport,
    })
  )
)

const PayrollVarianceSummary = lazy(() =>
  import('./Payroll/PayrollListing/PayrollVarianceSummary/PayrollVarianceSummary').then(module => ({
    default: module.PayrollVarianceSummary,
  }))
)

const PayrollVarianceSummaryReport = lazy(() =>
  import('./Payroll/PayrollListing/PayrollVarianceSummary/PayrollVarianceSummaryReport').then(module => ({
    default: module.PayrollVarianceSummaryReport,
  }))
)

// const SalaryPayoutAnalysisDetail = lazy(() =>
//   import('./Payroll/PayrollListing/SalaryPayoutAnalysisDetail').then(
//     module => ({
//       default: module.SalaryPayoutAnalysisDetail,
//     })
//   )
// )

const SalaryPayoutAnalysisDetail = lazy(() =>
  import('./Payroll/PayrollListing/SalaryPayoutAnalysisDetail/SalaryPayoutAnalysisDetail').then(
    module => ({
      default: module.SalaryPayoutAnalysisDetail,
    })
  )
)

const SalaryPayoutAnalysisDetailReport = lazy(() =>
  import('./Payroll/PayrollListing/SalaryPayoutAnalysisDetail/SalaryPayoutAnalysisDetailReport').then(
    module => ({
      default: module.SalaryPayoutAnalysisDetailReport,
    })
  )
)

const SalaryPayoutAnalysisDetailByCostCenter = lazy(() =>
  import(
    './Payroll/PayrollListing/SalaryPayoutAnalysisDetailByCostCenter/SalaryPayoutAnalysisDetailByCostCenter'
  ).then(module => ({
    default: module.SalaryPayoutAnalysisDetailByCostCenter,
  }))
)

const SalaryPayoutAnalysisDetailByCostCenterReport = lazy(() =>
  import(
    './Payroll/PayrollListing/SalaryPayoutAnalysisDetailByCostCenter/SalaryPayoutAnalysisDetailByCostCenterReport'
  ).then(module => ({
    default: module.SalaryPayoutAnalysisDetailByCostCenterReport,
  }))
)

const SalaryPayoutAnalysisDetailByStaffCategory = lazy(() =>
  import(
    './Payroll/PayrollListing/SalaryPayoutAnalysisDetailByStaffCategory/SalaryPayoutAnalysisDetailByStaffCategory'
  ).then(module => ({
    default: module.SalaryPayoutAnalysisDetailByStaffCategory,
  }))
)

const SalaryPayoutAnalysisDetailByStaffCategoryReport = lazy(() =>
  import(
    './Payroll/PayrollListing/SalaryPayoutAnalysisDetailByStaffCategory/SalaryPayoutAnalysisDetailByStaffCategoryRpt'
  ).then(module => ({
    default: module.SalaryPayoutAnalysisDetailByStaffCategoryReport,
  }))
)

const SalaryPayoutAnalysisDetailByJobPosition = lazy(() =>
  import(
    './Payroll/PayrollListing/SalaryPayoutAnalysisDetailByJobPosition/SalaryPayoutAnalysisDetailByJobPosition'
  ).then(module => ({
    default: module.SalaryPayoutAnalysisDetailByJobPosition,
  }))
)

const SalaryPayoutAnalysisDetailByJobPositionReport = lazy(() =>
  import(
    './Payroll/PayrollListing/SalaryPayoutAnalysisDetailByJobPosition/SalaryPayoutAnalysisDetailByJobPositionReport'
  ).then(module => ({
    default: module.SalaryPayoutAnalysisDetailByJobPositionReport,
  }))
)

const SalaryPayoutAnalysisSummaryByCostCenter = lazy(() =>
  import(
    './Payroll/PayrollListing/SalaryPayoutAnalysisSummaryByCostCenter/SalaryPayoutAnalysisSummaryByCostCenter'
  ).then(module => ({
    default: module.SalaryPayoutAnalysisSummaryByCostCenter,
  }))
)

const SalaryPayoutAnalysisSummaryByCostCenterReport = lazy(() =>
  import(
    './Payroll/PayrollListing/SalaryPayoutAnalysisSummaryByCostCenter/SalaryPayoutAnalysisSummaryByCostCenterReport'
  ).then(module => ({
    default: module.SalaryPayoutAnalysisSummaryByCostCenterReport,
  }))
)

const SalaryPayoutAnalysisSummary = lazy(() =>
  import(
    './Payroll/PayrollListing/SalaryPayoutAnalysisSummary/SalaryPayoutAnalysisSummary'
  ).then(module => ({
    default: module.SalaryPayoutAnalysisSummary,
  }))
)

const SalaryPayoutAnalysisSummaryReport = lazy(() =>
  import(
    './Payroll/PayrollListing/SalaryPayoutAnalysisSummary/SalaryPayoutAnalysisSummaryReport'
  ).then(module => ({
    default: module.SalaryPayoutAnalysisSummaryReport,
  }))
)

const SalaryPayoutAnalysisSummaryExcelExport = lazy(() =>
  import(
    './Payroll/PayrollListing/SalaryPayoutAnalysisSummaryExcelExport/SalaryPayoutAnalysisSummaryExcelExport'
  ).then(module => ({
    default: module.SalaryPayoutAnalysisSummaryExcelExport,
  }))
)

const StaffCurrentCompensation = lazy(() =>
  import(
    './Payroll/PayrollListing/StaffCurrentCompensation/StaffCurrentCompensation'
  ).then(module => ({
    default: module.StaffCurrentCompensation,
  }))
)

const StaffCurrentCompensationReport = lazy(() =>
  import(
    './Payroll/PayrollListing/StaffCurrentCompensation/StaffCurrentCompensationReport'
  ).then(module => ({
    default: module.StaffCurrentCompensationReport,
  }))
)

const ASBListing = lazy(() =>
  import('./Payroll/ASBListing/ASBListing').then(module => ({
    default: module.ASBListing,
  }))
)

const ASB = lazy(() =>
  import('./Payroll/ASBListing/ASB/ASB').then(module => ({
    default: module.ASB,
  }))
)

const ASBReport = lazy(() =>
  import('./Payroll/ASBListing/ASB/ASBReport').then(module => ({
    default: module.ASBReport,
  }))
)

const ASBCsvText = lazy(() =>
  import('./Payroll/ASBListing/ASBCsvText/ASBCsvText').then(module => ({
    default: module.ASBCsvText,
  }))
)

const ASBCsvTextReport = lazy(() =>
  import('./Payroll/ASBListing/ASBCsvText/ASBCsvTextReport').then(module => ({
    default: module.ASBCsvTextReport,
  }))
)

const HRDFFundListing = lazy(() =>
  import('./Payroll/HRDFFundListing/HRDFFundListing').then(module => ({
    default: module.HRDFFundListing,
  }))
)

const HRDFFundForm = lazy(() =>
  import('./Payroll/HRDFFundListing/HRDFFundForm').then(module => ({
    default: module.HRDFFundForm,
  }))
)

const HRDFFundView = lazy(() =>
  import('./Payroll/HRDFFundListing/HRDFFundView').then(module => ({
    default: module.HRDFFundView,
  }))
)

const HRDFFundView2 = lazy(() =>
  import('./Payroll/HRDFFundListing/HRDFFundView2').then(module => ({
    default: module.HRDFFundView2,
  }))
)

const HRDFFundListingByCompany = lazy(() =>
  import('./Payroll/HRDFFundListing/HRDFFundListingByCompany').then(module => ({
    default: module.HRDFFundListingByCompany,
  }))
)

const PayslipENBM = lazy(() =>
  import('./Payroll/Payslip/PayslipENBM/PayslipENBM').then(module => ({
    default: module.PayslipENBM,
  }))
)

const PayslipENBMReport = lazy(() =>
  import('./Payroll/Payslip/PayslipENBM/PayslipENBMReport').then(module => ({
    default: module.PayslipENBMReport,
  }))
)

const PayslipListing = lazy(() =>
  import('./Payroll/Payslip/PayslipListing').then(module => ({
    default: module.PayslipListing,
  }))
)

const ViewPayslip = lazy(() =>
  import('./Payroll/Payslip/ViewPayslip').then(module => ({
    default: module.ViewPayslip,
  }))
)

const GovernmentListingKWSP = lazy(() =>
  import('./Payroll/GovernmentListingKWSP/GovernmentListingKWSP').then(
    module => ({
      default: module.GovernmentListingKWSP,
    })
  )
)

const BorangA = lazy(() =>
  import('./Payroll/GovernmentListingKWSP/BorangA/BorangA').then(module => ({
    default: module.BorangA,
  }))
)

const BorangAReport = lazy(() =>
  import('./Payroll/GovernmentListingKWSP/BorangA/BorangAReport').then(module => ({
    default: module.BorangAReport,
  }))
)

const GovernmentEPF = lazy(() =>
  import('./Payroll/GovernmentListingKWSP/GovernmentEPF').then(module => ({
    default: module.GovernmentEPF,
  }))
)

const GovernmentEPFCsv = lazy(() =>
  import('./Payroll/GovernmentListingKWSP/GovernmentEPF/GovernmentEPFCsv').then(module => ({
    default: module.GovernmentEPFCsv,
  }))
)

const GovernmentEPFCsvReport = lazy(() =>
  import('./Payroll/GovernmentListingKWSP/GovernmentEPF/GovernmentEPFCsvReport').then(module => ({
    default: module.GovernmentEPFCsvReport,
  }))
)

const GovernmentEPFText = lazy(() =>
  import('./Payroll/GovernmentListingKWSP/GovernmentEPF/GovernmentEPFText').then(module => ({
    default: module.GovernmentEPFText,
  }))
)

const GovernmentEPFTextReport = lazy(() =>
  import('./Payroll/GovernmentListingKWSP/GovernmentEPF/GovernmentEPFTextReport').then(module => ({
    default: module.GovernmentEPFTextReport,
  }))
)

const GovernmentListingSOCSO = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/GovernmentListingSOCSO').then(
    module => ({
      default: module.GovernmentListingSOCSO,
    })
  )
)

const Borang2 = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/Borang2/Borang2').then(module => ({
    default: module.Borang2,
  }))
)

const Borang2Report = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/Borang2/Borang2Report').then(module => ({
    default: module.Borang2Report,
  }))
)

const Borang3 = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/Borang3/Borang3').then(module => ({
    default: module.Borang3,
  }))
)

const Borang3Report = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/Borang3/Borang3Report').then(module => ({
    default: module.Borang3Report,
  }))
)

const Borang8A = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/Borang8A/Borang8A').then(module => ({
    default: module.Borang8A,
  }))
)

const Borang8AReport = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/Borang8A/Borang8AReport').then(module => ({
    default: module.Borang8AReport,
  }))
)

const GovernmentSOCSO = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/GovernmentSOCSO').then(module => ({
    default: module.GovernmentSOCSO,
  }))
)
const GovernmentSOCSOText = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/GovernmentSOCSOText/GovernmentSOCSOText').then(module => ({
    default: module.GovernmentSOCSOText,
  }))
)
const GovernmentSOCSOTextReport = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/GovernmentSOCSOText/GovernmentSOCSOTextReport').then(module => ({
    default: module.GovernmentSOCSOTextReport,
  }))
)
const GovernmentSOCSOEIS = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/GovernmentSOCSOEIS').then(
    module => ({
      default: module.GovernmentSOCSOEIS,
    })
  )
)

const GovernmentSOCSOEISText = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/GovernmentSOCSOEISText/GovernmentSOCSOEISText').then(module => ({
    default: module.GovernmentSOCSOEISText,
  }))
)

const GovernmentSOCSOEISTextReport = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/GovernmentSOCSOEISText/GovernmentSOCSOEISTextReport').then(module => ({
    default: module.GovernmentSOCSOEISTextReport,
  }))
)
const GovernmentListingEIS = lazy(() =>
  import('./Payroll/GovernmentListingEIS/GovernmentListingEIS').then(
    module => ({
      default: module.GovernmentListingEIS,
    })
  )
)

const GovernmentEISText = lazy(() =>
  import('./Payroll/GovernmentListingEIS/GovernmentEISText/GovernmentEISText').then(module => ({
    default: module.GovernmentEISText,
  }))
)

const GovernmentEISTextReport = lazy(() =>
  import('./Payroll/GovernmentListingEIS/GovernmentEISText/GovernmentEISTextReport').then(module => ({
    default: module.GovernmentEISTextReport,
  }))
)

const EISText = lazy(() =>
  import('./Payroll/GovernmentListingEIS/EISText').then(module => ({
    default: module.EISText,
  }))
)

const Lampiran1 = lazy(() =>
  import('./Payroll/GovernmentListingEIS/Lampiran1/Lampiran1').then(module => ({
    default: module.Lampiran1,
  }))
)

const Lampiran1Report = lazy(() =>
  import('./Payroll/GovernmentListingEIS/Lampiran1/Lampiran1Report').then(module => ({
    default: module.Lampiran1Report,
  }))
)

const GovernmentListingLHDN = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/GovernmentListingLHDN').then(
    module => ({
      default: module.GovernmentListingLHDN,
    })
  )
)

const BorangEForm = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangE/BorangEForm').then(
    module => ({
      default: module.BorangEForm,
    })
  )
)

const BorangEReport = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangE/BorangEReport').then(
    module => ({
      default: module.BorangEReport,
    })
  )
)

const BorangEListing = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangE/BorangEListing').then(
    module => ({
      default: module.BorangEListing,
    })
  )
)

const BorangE = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangE').then(module => ({
    default: module.BorangE,
  }))
)

const BorangE2 = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangE/BorangE2').then(module => ({
    default: module.BorangE2,
  }))
)

const BorangEA2022ENBM = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangEA/BorangEA2022ENBM/BorangEA2022ENBM').then(module => ({
    default: module.BorangEA2022ENBM,
  }))
)

const BorangEA2022ENBMReport = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangEA/BorangEA2022ENBM/BorangEA2022ENBMReport').then(module => ({
    default: module.BorangEA2022ENBMReport,
  }))
)

const BorangEAForm = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangEA/BorangEAForm').then(module => ({
    default: module.BorangEAForm,
  }))
)

const BorangEAReport = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangEA/BorangEAReport').then(module => ({
    default: module.BorangEAReport,
  }))
)

const BorangEAListing = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangEA/BorangEAListing').then(
    module => ({
      default: module.BorangEAListing,
    })
  )
)

const BorangEA = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangEA').then(module => ({
    default: module.BorangEA,
  }))
)

const BorangEA2 = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangEA/BorangEA2').then(module => ({
    default: module.BorangEA2,
  }))
)

const BorangEA2022 = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangEA/BorangEA2022').then(
    module => ({
      default: module.BorangEA2022,
    })
  )
)

const BorangEA2023ENBM = lazy(() =>
  import(
    './Payroll/GovernmentListingLHDN/BorangEA/BorangEA2023ENBM/BorangEA2023ENBM'
  ).then(module => ({
    default: module.BorangEA2023ENBM,
  }))
)

const BorangEA2023ENBMReport = lazy(() =>
  import(
    './Payroll/GovernmentListingLHDN/BorangEA/BorangEA2023ENBM/BorangEA2023ENBMReport'
  ).then(module => ({
    default: module.BorangEA2023ENBMReport,
  }))
)

const CP159 = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP159/CP159').then(module => ({
    default: module.CP159,
  }))
)

const CP159Report = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP159/CP159Report').then(module => ({
    default: module.CP159Report,
  }))
)

const CP21 = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP21/CP21Form').then(module => ({
    default: module.CP21Form,
  }))
)

const CP21Report = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP21/CP21Report').then(module => ({
    default: module.CP21Report,
  }))
)

const CP22 = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP22/CP22').then(module => ({
    default: module.CP22,
  }))
)
const CP22Text = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP22/CP22Text').then(module => ({
    default: module.CP22Text,
  }))
)
const CP22TextReport = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP22/CP22TextReport').then(module => ({
    default: module.CP22TextReport,
  }))
)
const CP22Excel = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP22/CP22Excel').then(module => ({
    default: module.CP22Excel,
  }))
)
const CP22ExcelReport = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP22/CP22ExcelReport').then(module => ({
    default: module.CP22ExcelReport,
  }))
)
const CP22Report = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP22/CP22Report').then(module => ({
    default: module.CP22Report,
  }))
)

const CP22A = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP22A/CP22A').then(module => ({
    default: module.CP22A,
  }))
)

const CP22AReport = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP22A/CP22AReport').then(module => ({
    default: module.CP22AReport,
  }))
)

const CP22AText = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP22A/CP22AText').then(module => ({
    default: module.CP22AText,
  }))
)

const CP22ATextReport = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP22A/CP22ATextReport').then(module => ({
    default: module.CP22ATextReport,
  }))
)

const CP39 = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP39').then(module => ({
    default: module.CP39,
  }))
)

const CP39A = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP39A').then(module => ({
    default: module.CP39A,
  }))
)
const LHDNCP39 = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/LHDNCP39/CP39').then(module => ({
    default: module.CP39,
  }))
)
const LHDNCP39Report = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/LHDNCP39/CP39Report').then(module => ({
    default: module.CP39Report,
  }))
)

const LHDNCP39A = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/LHDNCP39A/CP39A').then(module => ({
    default: module.CP39A,
  }))
)

const LHDNCP39AReport = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/LHDNCP39A/CP39AReport').then(module => ({
    default: module.CP39AReport,
  }))
)
const CP8D2023 = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/LHDNCP8D/CP8D2023/CP8D2023').then(
    module => ({
      default: module.CP8D2023,
    })
  )
)
const CP8D2023Report = lazy(() =>
  import(
    './Payroll/GovernmentListingLHDN/LHDNCP8D/CP8D2023/CP8D2023Report'
  ).then(module => ({
    default: module.CP8D2023Report,
  }))
)

const CP8D = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP8D/CP8D').then(module => ({
    default: module.CP8D,
  }))
)

const CP8DReport = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP8D/CP8DReport').then(module => ({
    default: module.CP8DReport,
  }))
)

const CP8DListing = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/LHDNCP8D/CP8DListing').then(module => ({
    default: module.CP8DListing,
  }))
)

const CP8D2023Text = lazy(() =>
  import(
    './Payroll/GovernmentListingLHDN/LHDNCP8DText/CP8D2023Text/CP8D2023Text'
  ).then(module => ({
    default: module.CP8D2023Text,
  }))
)

const CP8D2023TextReport = lazy(() =>
  import(
    './Payroll/GovernmentListingLHDN/LHDNCP8DText/CP8D2023Text/CP8D2023TextReport'
  ).then(module => ({
    default: module.CP8D2023TextReport,
  }))
)

const CP8DText = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP8DText/CP8DText').then(module => ({
    default: module.CP8DText,
  }))
)
const CP8DTextReport = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP8DText/CP8DTextReport').then(
    module => ({
      default: module.CP8DTextReport,
    })
  )
)

const CP8DTextListing = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/LHDNCP8DText/CP8DTextListing').then(
    module => ({
      default: module.CP8DTextListing,
    })
  )
)

const TaxText = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/LHDNTax/TaxText').then(
    module => ({
      default: module.TaxText,
    })
  )
)

const TaxTextReport = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/LHDNTax/TaxTextReport').then(
    module => ({
      default: module.TaxTextReport,
    })
  )
)

const LampiranB = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/LampiranB/LampiranB').then(module => ({
    default: module.LampiranB,
  }))
)

const LampiranBReport = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/LampiranB/LampiranBReport').then(module => ({
    default: module.LampiranBReport,
  }))
)

const BankIntegrationFiles = lazy(() =>
  import('./Payroll/BankIntegrationFiles/BankIntegrationFiles').then(
    module => ({
      default: module.BankIntegrationFiles,
    })
  )
)

const BankIntegrationFilesListing = lazy(() =>
  import('./Payroll/BankIntegrationFiles/BankIntegrationFilesListing').then(
    module => ({
      default: module.BankIntegrationFilesListing,
    })
  )
)
const AFFBSalaryDownloadExcel = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/AFFB/AFFBSalary/AFFBSalaryDownloadExcel'
  ).then(module => ({
    default: module.AFFBSalaryDownloadExcel,
  }))
)
const AFFBSalaryDownloadExcelReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/AFFB/AFFBSalary/AFFBSalaryDownloadExcelReport'
  ).then(module => ({
    default: module.AFFBSalaryDownloadExcelReport,
  }))
)

const ABMBSalaryDownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/ABMB/ABMBSalary/ABMBSalaryDownloadText'
  ).then(module => ({
    default: module.ABMBSalaryDownloadText,
  }))
)
const ABMBSalaryDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/ABMB/ABMBSalary/ABMBSalaryDownloadTextReport'
  ).then(module => ({
    default: module.ABMBSalaryDownloadTextReport,
  }))
)

const AMBBSalaryDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/AMBB/AMBBSalaryDownloadText').then(module => ({
    default: module.AMBBSalaryDownloadText,
  }))
)
const AMBBSalaryDownloadTextReport = lazy(() =>
  import('./Payroll/BankIntegrationFiles/AMBB/AMBBSalaryDownloadTextReport').then(module => ({
    default: module.AMBBSalaryDownloadTextReport,
  }))
)
const AMMBSalaryDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/AMMB/AMMBSalaryDownloadText').then(
    module => ({
      default: module.AMMBSalaryDownloadText,
    })
  )
)
const AMMBSalaryDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/AMMB/AMMBSalaryDownloadTextReport'
  ).then(module => ({
    default: module.AMMBSalaryDownloadTextReport,
  }))
)

const AMMBLHDNDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/AMMB/AMMBLHDNDownloadText').then(
    module => ({
      default: module.AMMBLHDNDownloadText,
    })
  )
)
const AMMBLHDNDownloadTextReport = lazy(() =>
  import('./Payroll/BankIntegrationFiles/AMMB/AMMBLHDNDownloadTextReport').then(
    module => ({
      default: module.AMMBLHDNDownloadTextReport,
    })
  )
)

const AMMBEPFDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/AMMB/AMMBEPFDownloadText').then(
    module => ({
      default: module.AMMBEPFDownloadText,
    })
  )
)
const AMMBEPFDownloadTextReport = lazy(() =>
  import('./Payroll/BankIntegrationFiles/AMMB/AMMBEPFDownloadTextReport').then(
    module => ({
      default: module.AMMBEPFDownloadTextReport,
    })
  )
)

const AMMBSOCSODownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/AMMB/AMMBSOCSODownloadText').then(
    module => ({
      default: module.AMMBSOCSODownloadText,
    })
  )
)
const AMMBSOCSODownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/AMMB/AMMBSOCSODownloadTextReport'
  ).then(module => ({
    default: module.AMMBSOCSODownloadTextReport,
  }))
)

const AMMBEISDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/AMMB/AMMBEISDownloadText').then(
    module => ({
      default: module.AMMBEISDownloadText,
    })
  )
)
const AMMBEISDownloadTextReport = lazy(() =>
  import('./Payroll/BankIntegrationFiles/AMMB/AMMBEISDownloadTextReport').then(
    module => ({
      default: module.AMMBEISDownloadTextReport,
    })
  )
)

const BIMBSalaryText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/BIMB/BIMBSalaryText').then(module => ({
    default: module.BIMBSalaryText,
  }))
)

const BIMBSalaryDownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/BIMB/BIMBSalary/BIMBSalaryDownloadText'
  ).then(module => ({
    default: module.BIMBSalaryDownloadText,
  }))
)

const BIMBSalaryDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/BIMB/BIMBSalary/BIMBSalaryDownloadTextReport'
  ).then(module => ({
    default: module.BIMBSalaryDownloadTextReport,
  }))
)

const HLBBSalaryExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/HLBB/HLBBSalaryExcel').then(
    module => ({
      default: module.HLBBSalaryExcel,
    })
  )
)

const HLBBSalaryDownloadExcel = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/HLBB/HLBBSalary/HLBBSalaryDownloadExcel'
  ).then(module => ({
    default: module.HLBBSalaryDownloadExcel,
  }))
)

const HLBBSalaryDownloadExcelReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/HLBB/HLBBSalary/HLBBSalaryDownloadExcelReport'
  ).then(module => ({
    default: module.HLBBSalaryDownloadExcelReport,
  }))
)

const HLBBEPFExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/HLBB/HLBBEPFExcel').then(module => ({
    default: module.HLBBEPFExcel,
  }))
)

const HLBBEPFDownloadExcel = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/HLBB/HLBBEPF/HLBBEPFDownloadExcel'
  ).then(module => ({
    default: module.HLBBEPFDownloadExcel,
  }))
)

const HLBBEPFDownloadExcelReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/HLBB/HLBBEPF/HLBBEPFDownloadExcelReport'
  ).then(module => ({
    default: module.HLBBEPFDownloadExcelReport,
  }))
)

const HLBBSOCSOExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/HLBB/HLBBSOCSOExcel').then(module => ({
    default: module.HLBBSOCSOExcel,
  }))
)

const HLBBSOCSODownloadExcel = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/HLBB/HLBBSOCSO/HLBBSOCSODownloadExcel'
  ).then(module => ({
    default: module.HLBBSOCSODownloadExcel,
  }))
)

const HLBBSOCSODownloadExcelReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/HLBB/HLBBSOCSO/HLBBSOCSODownloadExcelReport'
  ).then(module => ({
    default: module.HLBBSOCSODownloadExcelReport,
  }))
)

const HLBBLHDNExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/HLBB/HLBBLHDNExcel').then(module => ({
    default: module.HLBBLHDNExcel,
  }))
)

const HLBBLHDNDownloadExcel = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/HLBB/HLBBLHDN/HLBBLHDNDownloadExcel'
  ).then(module => ({
    default: module.HLBBLHDNDownloadExcel,
  }))
)

const HLBBLHDNDownloadExcelReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/HLBB/HLBBLHDN/HLBBLHDNDownloadExcelReport'
  ).then(module => ({
    default: module.HLBBLHDNDownloadExcelReport,
  }))
)

const HLBBTabungHajiExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/HLBB/HLBBTabungHajiExcel').then(
    module => ({
      default: module.HLBBTabungHajiExcel,
    })
  )
)

const HLBBTabungHajiDownloadExcel = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/HLBB/HLBBTabungHaji/HLBBTabungHajiDownloadExcel'
  ).then(module => ({
    default: module.HLBBTabungHajiDownloadExcel,
  }))
)

const HLBBTabungHajiDownloadExcelReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/HLBB/HLBBTabungHaji/HLBBTabungHajiDownloadExcelReport'
  ).then(module => ({
    default: module.HLBBTabungHajiDownloadExcelReport,
  }))
)

const HLBBZakatExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/HLBB/HLBBZakatExcel').then(module => ({
    default: module.HLBBZakatExcel,
  }))
)

const HLBBZakatDownloadExcel = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/HLBB/HLBBZakat/HLBBZakatDownloadExcel'
  ).then(module => ({
    default: module.HLBBZakatDownloadExcel,
  }))
)

const HLBBZakatDownloadExcelReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/HLBB/HLBBZakat/HLBBZakatDownloadExcelReport'
  ).then(module => ({
    default: module.HLBBZakatDownloadExcelReport,
  }))
)

const RHBExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/RHB/RHBExcel').then(module => ({
    default: module.RHBExcel,
  }))
)

const RHBSalaryDownloadExcel = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/RHB/RHBSalary/RHBSalaryDownloadExcel'
  ).then(module => ({
    default: module.RHBSalaryDownloadExcel,
  }))
)

const RHBSalaryDownloadExcelReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/RHB/RHBSalary/RHBSalaryDownloadExcelReport'
  ).then(module => ({
    default: module.RHBSalaryDownloadExcelReport,
  }))
)

const RHBIBGExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/RHB/RHBIBGExcel').then(module => ({
    default: module.RHBIBGExcel,
  }))
)

const RHBIBGSalaryDownloadExcel = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/RHB/RHBSalary/RHBIBGSalaryDownloadExcel'
  ).then(module => ({
    default: module.RHBIBGSalaryDownloadExcel,
  }))
)

const RHBIBGSalaryDownloadExcelReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/RHB/RHBSalary/RHBIBGSalaryDownloadExcelReport'
  ).then(module => ({
    default: module.RHBIBGSalaryDownloadExcelReport,
  }))
)

const RHBEPFExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/RHB/RHBEPFExcel').then(module => ({
    default: module.RHBEPFExcel,
  }))
)

const RHBEPFDownloadExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/RHB/RHBEPF/RHBEPFDownloadExcel').then(
    module => ({
      default: module.RHBEPFDownloadExcel,
    })
  )
)

const RHBEPFDownloadExcelReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/RHB/RHBEPF/RHBEPFDownloadExcelReport'
  ).then(module => ({
    default: module.RHBEPFDownloadExcelReport,
  }))
)

const RHBSOCSOExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/RHB/RHBSOCSOExcel').then(module => ({
    default: module.RHBSOCSOExcel,
  }))
)

const RHBSOCSODownloadExcel = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/RHB/RHBSOCSO/RHBSOCSODownloadExcel'
  ).then(module => ({
    default: module.RHBSOCSODownloadExcel,
  }))
)

const RHBSOCSODownloadExcelReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/RHB/RHBSOCSO/RHBSOCSODownloadExcelReport'
  ).then(module => ({
    default: module.RHBSOCSODownloadExcelReport,
  }))
)

const RHBEISExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/RHB/RHBEISExcel').then(module => ({
    default: module.RHBEISExcel,
  }))
)

const RHBEISDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/RHB/RHBEIS/RHBEISDownloadText').then(
    module => ({
      default: module.RHBEISDownloadText,
    })
  )
)

const RHBEISDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/RHB/RHBEIS/RHBEISDownloadTextReport'
  ).then(module => ({
    default: module.RHBEISDownloadTextReport,
  }))
)

const RHBLHDNExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/RHB/RHBLHDNExcel').then(module => ({
    default: module.RHBLHDNExcel,
  }))
)

const RHBLHDNDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/RHB/RHBLHDN/RHBLDHNDownloadText').then(
    module => ({
      default: module.RHBLHDNDownloadText,
    })
  )
)

const RHBLHDNDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/RHB/RHBLHDN/RHBLDHNDownloadTextReport'
  ).then(module => ({
    default: module.RHBLHDNDownloadTextReport,
  }))
)


const CIMBSalaryDownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/CIMB/CIMBSalary/CIMBSalaryDownloadText'
  ).then(module => ({
    default: module.CIMBSalaryDownloadText,
  }))
)

const CIMBSalaryDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/CIMB/CIMBSalary/CIMBSalaryDownloadTextReport'
  ).then(module => ({
    default: module.CIMBSalaryDownloadTextReport,
  }))
)

const CIMBEPFDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/CIMB/CIMBEPF/CIMBEPFDownloadText').then(
    module => ({
      default: module.CIMBEPFDownloadText,
    })
  )
)

const CIMBEPFDownloadTextReport = lazy(() =>
  import('./Payroll/BankIntegrationFiles/CIMB/CIMBEPF/CIMBEPFDownloadTextReport').then(
    module => ({
      default: module.CIMBEPFDownloadTextReport,
    })
  )
)

const CIMBLHDNDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/CIMB/CIMBLHDN/CIMBLHDNDownloadText').then(
    module => ({
      default: module.CIMBLHDNDownloadText,
    })
  )
)

const CIMBLHDNDownloadTextReport = lazy(() =>
  import('./Payroll/BankIntegrationFiles/CIMB/CIMBLHDN/CIMBLHDNDownloadTextReport').then(
    module => ({
      default: module.CIMBLHDNDownloadTextReport,
    })
  )
)

const CIMBZakatDownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/CIMB/CIMBZakat/CIMBZakatDownloadText'
  ).then(module => ({
    default: module.CIMBZakatDownloadText,
  }))
)

const CIMBZakatDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/CIMB/CIMBZakat/CIMBZakatDownloadTextReport'
  ).then(module => ({
    default: module.CIMBZakatDownloadTextReport,
  }))
)

const CIMBSOCSODownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/CIMB/CIMBSOCSO/CIMBSOCSODownloadText').then(
    module => ({
      default: module.CIMBSOCSODownloadText,
    })
  )
)

const CIMBSOCSODownloadTextReport = lazy(() =>
  import('./Payroll/BankIntegrationFiles/CIMB/CIMBSOCSO/CIMBSOCSODownloadTextReport').then(
    module => ({
      default: module.CIMBSOCSODownloadTextReport,
    })
  )
)

const CIMBEISDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/CIMB/CIMBEIS/CIMBEISDownloadText').then(
    module => ({
      default: module.CIMBEISDownloadText,
    })
  )
)

const CIMBEISDownloadTextReport = lazy(() =>
  import('./Payroll/BankIntegrationFiles/CIMB/CIMBEIS/CIMBEISDownloadTextReport').then(
    module => ({
      default: module.CIMBEISDownloadTextReport,
    })
  )
)



// MAYBANK
const M2UBizSalaryDownloadCsv = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/MAYBANK/MAYBANKSalary/M2UBizSalaryDownloadCsv'
  ).then(module => ({
    default: module.M2UBizSalaryDownloadCsv,
  }))
)
const M2UBizSalaryDownloadCsvReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/MAYBANK/MAYBANKSalary/M2UBizSalaryDownloadCsvReport'
  ).then(module => ({
    default: module.M2UBizSalaryDownloadCsvReport,
  }))
)
const M2ERCSalaryDownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/MAYBANK/MAYBANKSalary/M2ERCSalaryDownloadText'
  ).then(module => ({
    default: module.M2ERCSalaryDownloadText,
  }))
)
const M2ERCSalaryDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/MAYBANK/MAYBANKSalary/M2ERCSalaryDownloadTextReport'
  ).then(module => ({
    default: module.M2ERCSalaryDownloadTextReport,
  }))
)
const MaybankMASAPSDownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/MAYBANK/MAYBANKSalary/MaybankMASAPSDownloadText'
  ).then(module => ({
    default: module.MaybankMASAPSDownloadText,
  }))
)
const MaybankMASAPSDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/MAYBANK/MAYBANKSalary/MaybankMASAPSDownloadTextReport'
  ).then(module => ({
    default: module.MaybankMASAPSDownloadTextReport,
  }))
)
const M2ERCEPFDownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/MAYBANK/MAYBANKEPF/M2ERCEPFDownloadText'
  ).then(module => ({
    default: module.M2ERCEPFDownloadText,
  }))
)
const M2ERCEPFDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/MAYBANK/MAYBANKEPF/M2ERCEPFDownloadTextReport'
  ).then(module => ({
    default: module.M2ERCEPFDownloadTextReport,
  }))
)
const M2ERCTaxDownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/MAYBANK/MAYBANKTax/M2ERCTaxDownloadText'
  ).then(module => ({
    default: module.M2ERCTaxDownloadText,
  }))
)
const M2ERCTaxDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/MAYBANK/MAYBANKTax/M2ERCTaxDownloadTextReport'
  ).then(module => ({
    default: module.M2ERCTaxDownloadTextReport,
  }))
)
const M2ERCSOCSODownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/MAYBANK/MAYBANKSOCSO/M2ERCSOCSODownloadText'
  ).then(module => ({
    default: module.M2ERCSOCSODownloadText,
  }))
)
const M2ERCSOCSODownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/MAYBANK/MAYBANKSOCSO/M2ERCSOCSODownloadTextReport'
  ).then(module => ({
    default: module.M2ERCSOCSODownloadTextReport,
  }))
)
const M2ERCEISDownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/MAYBANK/MAYBANKEIS/M2ERCEISDownloadText'
  ).then(module => ({
    default: module.M2ERCEISDownloadText,
  }))
)
const M2ERCEISDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/MAYBANK/MAYBANKEIS/M2ERCEISDownloadTextReport'
  ).then(module => ({
    default: module.M2ERCEISDownloadTextReport,
  }))
)
const M2ERCZakatDownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/MAYBANK/MAYBANKZakat/M2ERCZakatDownloadText'
  ).then(module => ({
    default: module.M2ERCZakatDownloadText,
  }))
)
const M2ERCZakatDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/MAYBANK/MAYBANKZakat/M2ERCZakatDownloadTextReport'
  ).then(module => ({
    default: module.M2ERCZakatDownloadTextReport,
  }))
)

const UOBSalaryDownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/UOB/UOBSalary/UOBSalaryDownloadText'
  ).then(module => ({
    default: module.UOBSalaryDownloadText,
  }))
)

const UOBSalaryDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/UOB/UOBSalary/UOBSalaryDownloadTextReport'
  ).then(module => ({
    default: module.UOBSalaryDownloadTextReport,
  }))
)

const UOBEPFText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/UOB/UOBEPFText').then(module => ({
    default: module.UOBEPFText,
  }))
)

const UOBEISText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/UOB/UOBEISText').then(module => ({
    default: module.UOBEISText,
  }))
)

const UOBSOCSOText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/UOB/UOBSOCSOText').then(module => ({
    default: module.UOBSOCSOText,
  }))
)


const UOBEISDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/UOB/UOBEIS/UOBEISDownloadText').then(
    module => ({
      default: module.UOBEISDownloadText,
    })
  )
)

const UOBEISDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/UOB/UOBEIS/UOBEISDownloadTextReport'
  ).then(module => ({
    default: module.UOBEISDownloadTextReport,
  }))
)

const UOBEPFDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/UOB/UOBEPF/UOBEPFDownloadText').then(
    module => ({
      default: module.UOBEPFDownloadText,
    })
  )
)

const UOBEPFDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/UOB/UOBEPF/UOBEPFDownloadTextReport'
  ).then(module => ({
    default: module.UOBEPFDownloadTextReport,
  }))
)

const UOBSOCSODownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/UOB/UOBSOCSO/UOBSOCSODownloadText'
  ).then(module => ({
    default: module.UOBSOCSODownloadText,
  }))
)

const UOBSOCSODownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/UOB/UOBSOCSO/UOBSOCSODownloadTextReport'
  ).then(module => ({
    default: module.UOBSOCSODownloadTextReport,
  }))
)



const HSBCSalaryText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/HSBC/HSBCSalaryText').then(module => ({
    default: module.HSBCSalaryText,
  }))
)

const HSBCSalaryDownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/HSBC/HSBCSalary/HSBCSalaryDownloadText'
  ).then(module => ({
    default: module.HSBCSalaryDownloadText,
  }))
)

const HSBCSalaryDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/HSBC/HSBCSalary/HSBCSalaryDownloadTextReport'
  ).then(module => ({
    default: module.HSBCSalaryDownloadTextReport,
  }))
)

const OCBCSalaryDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/OCBC/OCBCSalary/OCBCSalaryText').then(
    module => ({
      default: module.OCBCSalaryText,
    })
  )
)

const OCBCSalaryDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/OCBC/OCBCSalary/OCBCSalaryTextReport'
  ).then(module => ({
    default: module.OCBCSalaryTextReport,
  }))
)

const OCBCSOCSODownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/OCBC/OCBCSOCSO/OCBCSOCSODownloadText'
  ).then(module => ({
    default: module.OCBCSOCSODownloadText,
  }))
)
const OCBCLHDNDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/OCBC/OCBCLHDN/OCBCLHDNText').then(
    module => ({
      default: module.OCBCLHDNDownloadText,
    })
  )
)

const OCBCSOCSODownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/OCBC/OCBCSOCSO/OCBCSOCSODownloadTextReport'
  ).then(module => ({
    default: module.OCBCSOCSODownloadTextReport,
  }))
)

const OCBCEISDownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/OCBC/OCBCEIS/OCBCEISDownloadText'
  ).then(module => ({
    default: module.OCBCEISDownloadText,
  }))
)

const OCBCEISDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/OCBC/OCBCEIS/OCBCEISDownloadTextReport'
  ).then(module => ({
    default: module.OCBCEISDownloadTextReport,
  }))
)
const OCBCLHDNDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/OCBC/OCBCLHDN/OCBCLHDNTextReport'
  ).then(module => ({
    default: module.OCBCLHDNTextReport,
  }))
)

const OCBCEPFDownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/OCBC/OCBCEPF/OCBCEPFDownloadText'
  ).then(module => ({
    default: module.OCBCEPFDownloadText,
  }))
)

const OCBCEPFDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/OCBC/OCBCEPF/OCBCEPFDownloadTextReport'
  ).then(module => ({
    default: module.OCBCEPFDownloadTextReport,
  }))
)
const PBBSalaryDownloadExcel = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/PBB/PBBSalary/PBBSalaryDownloadExcel'
  ).then(module => ({
    default: module.PBBSalaryDownloadExcel,
  }))
)

const PBBSalaryDownloadExcelReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/PBB/PBBSalary/PBBSalaryDownloadExcelReport'
  ).then(module => ({
    default: module.PBBSalaryDownloadExcelReport,
  }))
)

const PBBSalaryDownloadText = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/PBB/PBBSalary/PBBSalaryDownloadText'
  ).then(module => ({
    default: module.PBBSalaryDownloadText,
  }))
)

const PBBSalaryDownloadTextReport = lazy(() =>
  import(
    './Payroll/BankIntegrationFiles/PBB/PBBSalary/PBBSalaryDownloadTextReport'
  ).then(module => ({
    default: module.PBBSalaryDownloadTextReport,
  }))
)
const LeaveAnalysis = lazy(() =>
  import('./Leave/LeaveAnalysis').then(module => ({
    default: module.LeaveAnalysis,
  }))
)

const LeaveAnalysisReport = lazy(() =>
  import('./Leave/LeaveAnalysisReport').then(module => ({
    default: module.LeaveAnalysisReport,
  }))
)

const LeaveEntitlementBalanceAnalysis = lazy(() =>
  import(
    './Leave/LeaveEntitlementBalanceAnalysis/LeaveEntitlementBalanceAnalysis'
  ).then(module => ({
    default: module.LeaveEntitlementBalanceAnalysis,
  }))
)

const LeaveEntitlementBalanceAnalysisReport = lazy(() =>
  import(
    './Leave/LeaveEntitlementBalanceAnalysis/LeaveEntitlementBalanceAnalysisReport'
  ).then(module => ({
    default: module.LeaveEntitlementBalanceAnalysisReport,
  }))
)

const ClaimBalanceAnalysis = lazy(() =>
  import('./Claim/ClaimBalanceAnalysis/ClaimBalanceAnalysis').then(module => ({
    default: module.ClaimBalanceAnalysis,
  }))
)

const ClaimBalanceAnalysisReport = lazy(() =>
  import('./Claim/ClaimBalanceAnalysis/ClaimBalanceAnalysisReport').then(module => ({
    default: module.ClaimBalanceAnalysisReport,
  }))
)

const ClaimStatusAnalysis = lazy(() =>
  import('./Claim/ClaimStatusAnalaysis/ClaimStatusAnalysis').then(module => ({
    default: module.ClaimStatusAnalysis,
  }))
)

const ClaimStatusAnalysisReport = lazy(() =>
  import('./Claim/ClaimStatusAnalaysis/ClaimStatusAnalysisReport').then(module => ({
    default: module.ClaimStatusAnalysisReport,
  }))
)

const ClaimDetailListing = lazy(() =>
  import('./Claim/ClaimDetailListing/ClaimDetailListing').then(module => ({
    default: module.ClaimDetailListing,
  }))
)
const StaffClaim = lazy(() =>
  import('./Claim/StaffClaim/StaffClaim').then(module => ({
    default: module.StaffClaim,
  }))
)

const StaffClaimReport = lazy(() =>
  import('./Claim/StaffClaim/StaffClaimReport').then(module => ({
    default: module.StaffClaimReport,
  }))
)

const BankClaimIntegrationFiles = lazy(() =>
  import('./Claim/BankClaimIntegrationFiles/BankClaimIntegrationFiles').then(
    module => ({
      default: module.BankClaimIntegrationFiles,
    })
  )
)

const BankClaimIntegrationFilesListing = lazy(() =>
  import(
    './Claim/BankClaimIntegrationFiles/BankClaimIntegrationFilesListing'
  ).then(module => ({
    default: module.BankClaimIntegrationFilesListing,
  }))
)

const HLBBClaimExcel = lazy(() =>
  import('./Claim/BankClaimIntegrationFiles/HLBB/HLBBClaimExcel').then(
    module => ({
      default: module.HLBBClaimExcel,
    })
  )
)

const ZakatListing = lazy(() =>
  import('./Payroll/ZakatListing/ZakatListing').then(module => ({
    default: module.ZakatListing,
  }))
)

const ZakatWPKL = lazy(() =>
  import('./Payroll/ZakatListing/ZakatListingWPKL/ZakatListingWPKL').then(
    module => ({
      default: module.ZakatListingWPKL,
    })
  )
)

const ZakatWPKLReport = lazy(() =>
  import('./Payroll/ZakatListing/ZakatListingWPKL/ZakatListingWPKLReport').then(
    module => ({
      default: module.ZakatListingWPKLReport,
    })
  )
)

const ZakatListingView = lazy(() =>
  import('./Payroll/ZakatListing/ZakatListingView').then(module => ({
    default: module.ZakatListingView,
  }))
)

const TabungHajiListing = lazy(() =>
  import('./Payroll/TabungHajiListing/TabungHajiListing').then(module => ({
    default: module.TabungHajiListing,
  }))
)

const TabungHaji = lazy(() =>
  import('./Payroll/TabungHajiListing/TabungHaji/TabungHaji').then(module => ({
    default: module.TabungHaji,
  }))
)

const TabungHajiReport = lazy(() =>
  import('./Payroll/TabungHajiListing/TabungHaji/TabungHajiReport').then(module => ({
    default: module.TabungHajiReport,
  }))
)

const TabungHajiExcel = lazy(() =>
  import('./Payroll/TabungHajiListing/TabungHajiExcel/TabungHajiExcel').then(module => ({
    default: module.TabungHajiExcel,
  }))
)

const TabungHajiExcelReport = lazy(() =>
  import('./Payroll/TabungHajiListing/TabungHajiExcel/TabungHajiExcelReport').then(module => ({
    default: module.TabungHajiExcelReport,
  }))
)

const TimeAttendanceAnalysis = lazy(() =>
  import('./TimeAttendance/TimeAttendanceAnalysis').then(module => ({
    default: module.TimeAttendanceAnalysis,
  }))
)
const PeriodicOvertimeForecastListingReport = lazy(() =>
  import(
    './TimeAttendance/PeriodicOvertimeForecastListing/PeriodicOvertimeForecastListingReport'
  ).then(module => ({
    default: module.PeriodicOvertimeForecastListingReport,
  }))
)

const PeriodicOvertimeForecastListingReportExcel = lazy(() =>
  import(
    './TimeAttendance/PeriodicOvertimeForecastListing/PeriodicOvertimeForecastListingReportExcel'
  ).then(module => ({
    default: module.PeriodicOvertimeForecastListingReportExcel,
  }))
)

const HelpdeskListingReport = lazy(() =>
  import('./Helpdesk/HelpdeskListingReport').then(module => ({
    default: module.HelpdeskListingReport,
  }))
)

const ProjectTimesheet = lazy(() => 
  import('./ProjectTimesheet/ProjectTimesheet').then(module => ({
    default: module.ProjectTimesheet,
  }))
)

const ProjectTimesheetReport = lazy(() => 
  import('./ProjectTimesheet/ProjectTimesheetReport').then(module => ({
    default: module.ProjectTimesheetReport,
  }))
)

const ProjectTimesheetSummary = lazy(() => 
  import('./ProjectTimesheet/ProjectTimesheetSummary').then(module => ({
    default: module.ProjectTimesheetSummary,
  }))
)

const ProjectTimesheetSummaryReport = lazy(() => 
  import('./ProjectTimesheet/ProjectTimesheetSummaryReport').then(module => ({
    default: module.ProjectTimesheetSummaryReport,
  }))
)

const AttendanceListingReport = lazy(() =>
  import('./TimeAttendance/AttendanceListing/AttendanceListingReport').then(module => ({
    default: module.AttendanceListingReport,
  }))
)

const AttendanceListingReportExcel = lazy(() =>
  import(
    './TimeAttendance/AttendanceListing/AttendanceListingReportExcel'
  ).then(module => ({
    default: module.AttendanceListingReportExcel,
  }))
)

const EmployeeClockingListingReport = lazy(() =>
  import('./TimeAttendance/EmployeeClockingListing/EmployeeClockingListingReport').then(module => ({
    default: module.EmployeeClockingListingReport,
  }))
)

const EmployeeClockingListingReportExcel = lazy(() =>
  import(
    './TimeAttendance/EmployeeClockingListing/EmployeeClockingListingReportExcel'
  ).then(module => ({
    default: module.EmployeeClockingListingReportExcel,
  }))
)

const AbsenteeismListingReport = lazy(() =>
  import('./TimeAttendance/AbsenteeismListing/AbsenteeismListingReport').then(module => ({
    default: module.AbsenteeismListingReport,
  }))
)

const AbsenteeismListingReportExcel = lazy(() =>
  import(
    './TimeAttendance/AbsenteeismListing/AbsenteeismListingReportExcel'
  ).then(module => ({
    default: module.AbsenteeismListingReportExcel,
  }))
)

const IncompleteClockingListingReport = lazy(() =>
  import('./TimeAttendance/IncompleteClockingListing/IncompleteClockingListingReport').then(module => ({
    default: module.IncompleteClockingListingReport,
  }))
)

const IncompleteClockingListingReportExcel = lazy(() =>
  import(
    './TimeAttendance/IncompleteClockingListing/IncompleteClockingListingReportExcel'
  ).then(module => ({
    default: module.IncompleteClockingListingReportExcel,
  }))
)

const IrregularAttendanceListingReport = lazy(() =>
  import('./TimeAttendance/IrregularAttendanceListing/IrregularAttendanceListingReport').then(module => ({
    default: module.IrregularAttendanceListingReport,
  }))
)

const IrregularAttendanceListingReportExcel = lazy(() =>
  import(
    './TimeAttendance/IrregularAttendanceListing/IrregularAttendanceListingReportExcel'
  ).then(module => ({
    default: module.IrregularAttendanceListingReportExcel,
  }))
)

const LateInEarlyOutListingReport = lazy(() =>
  import('./TimeAttendance/LateInEarlyOutListing/LateInEarlyOutListingReport').then(module => ({
    default: module.LateInEarlyOutListingReport,
  }))
)

const LateInEarlyOutListingReportExcel = lazy(() =>
  import(
    './TimeAttendance/LateInEarlyOutListing/LateInEarlyOutListingReportExcel'
  ).then(module => ({
    default: module.LateInOrEarlyOutListingReportExcel,
  }))
)

const DigitalReportingManagementRoutes = [
  {
    props: { exact: true, path: '/Personnel/EmployeeBasicInfo' },
    component: <EmployeeBasicInfo />,
  },
  {
    props: { exact: true, path: '/Personnel/EmployeeBasicInfo/Rpt' },
    component: <EmployeeBasicInfoReport />,
  },
  {
    props: { exact: true, path: '/Personnel/EmployeeConfirmation' },
    component: <EmployeeConfirmation />,
  },
  {
    props: { exact: true, path: '/Personnel/EmployeeConfirmation/Rpt' },
    component: <EmployeeConfirmationReport />,
  },
  {
    props: { exact: true, path: '/Personnel/EmployeeDependent' },
    component: <EmployeeDependent />,
  },
  {
    props: { exact: true, path: '/Personnel/EmployeeDependent/Rpt' },
    component: <EmployeeDependentReport />,
  },
  {
    props: { exact: true, path: '/Personnel/EmployeeEducation' },
    component: <EmployeeEducation />,
  },
  {
    props: { exact: true, path: '/Personnel/EmployeeEducation/Rpt' },
    component: <EmployeeEducationReport />,
  },
  {
    props: { exact: true, path: '/Personnel/EmployeeNewJoin' },
    component: <EmployeeNewJoin />,
  },
  {
    props: { exact: true, path: '/Personnel/EmployeeNewJoin/Rpt' },
    component: <EmployeeNewJoinReport />,
  },
  {
    props: { exact: true, path: '/Personnel/EmployeeResignation' },
    component: <EmployeeResignation />,
  },
  {
    props: { exact: true, path: '/Personnel/EmployeeResignation/Rpt' },
    component: <EmployeeResignationReport />,
  },
  {
    props: {
      exact: true,
      path: '/Personnel/EmployeeMasterExcel',
    },
    component: <EmployeeMasterExcelReport />,
  },
  {
    props: { exact: true, path: '/Personnel/EmployeeServiceMovement' },
    component: <EmployeeServiceMovement />,
  },
  {
    props: { exact: true, path: '/Personnel/EmployeeServiceMovement/Rpt' },
    component: <EmployeeServiceMovementReport />,
  },
  {
    props: { exact: true, path: '/Personnel/EmployeeServiceSalaryMovement' },
    component: <EmployeeServiceSalaryMovement />,
  },
  {
    props: { exact: true, path: '/Personnel/EmployeeServiceSalaryMovement/Rpt' },
    component: <EmployeeServiceSalaryMovementReport />,
  },
  {
    props: { exact: true, path: '/Payroll/Bank/BankListing' },
    component: <BankListing />,
  },
  {
    props: { exact: true, path: '/Payroll/Bank/BankPayoutListing' },
    component: <BankPayoutListing />,
  },
  {
    props: { exact: true, path: '/Payroll/Bank/BankPayoutListing/Rpt' },
    component: <BankPayoutListingReport />,
  },
  {
    props: { exact: true, path: '/Payroll/Bank/CashChequePayoutListing' },
    component: <CashChequePayoutListing />,
  },
  {
    props: { exact: true, path: '/Payroll/Bank/CashChequePayoutListing/Rpt' },
    component: <CashChequePayoutListingReport />,
  },
  {
    props: { exact: true, path: '/Payroll/PayrollListing/PayrollListing' },
    component: <PayrollListing />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/PayrollDetailListingExcelExport',
    },
    component: <PayrollDetailListingExcelExport />,
  },

  {
    props: { exact: true, path: '/Payroll/PayrollListing/EmployeePayHistory' },
    component: <EmployeePayHistory />,
  },
  {
    props: { exact: true, path: '/Payroll/PayrollListing/EmployeePayHistory/Rpt' },
    component: <EmployeePayHistoryReport />,
  },
  {
    props: { exact: true, path: '/Payroll/PayrollListing/EmployeeSalary' },
    component: <EmployeeSalary />,
  },
  {
    props: { exact: true, path: '/Payroll/PayrollListing/EmployeeSalary' },
    component: <EmployeeSalary />,
  },
  {
    props: { exact: true, path: '/Payroll/PayrollListing/EmployeeSalary/Rpt' },
    component: <EmployeeSalaryReport />,
  },
  {
    props: { exact: true, path: '/Payroll/PayrollListing/HistoryTransummary' },
    component: <HistoryTransummary />,
  },
  {
    props: { exact: true, path: '/Payroll/PayrollListing/HistoryTransSummaryByOrgUnit' },
    component: <HistoryTransSummaryByOrgUnit />,
  },
  {
    props: { exact: true, path: '/Payroll/PayrollListing/HistoryTransSummaryByOrgUnit/Rpt' },
    component: <HistoryTransSummaryByOrgUnitReport />,
  },
  {
    props: { exact: true, path: '/Payroll/PayrollListing/PayrollDetail' },
    component: <PayrollDetail />,
  },
  {
    props: { exact: true, path: '/Payroll/PayrollListing/PayrollDetailListing' },
    component: <PayrollDetailListing />,
  },
  {
    props: { exact: true, path: '/Payroll/PayrollListing/PayrollDetailListing/Rpt' },
    component: <PayrollDetailListingReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/PayrollTransactionDetailsList',
    },
    component: <PayrollTransactionDetailsList />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/PayrollTransactionDetailsList/Rpt',
    },
    component: <PayrollTransactionDetailsListReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/PayrollTransactionDetailsListing',
    },
    component: <PayrollTransactionDetailsListing />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/PayrollTransactionDetailsListing/Rpt',
    },
    component: <PayrollTransactionDetailsListingReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/PayrollVarianceSummary',
    },
    component: <PayrollVarianceSummary />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/PayrollVarianceSummary/Rpt',
    },
    component: <PayrollVarianceSummaryReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/SalaryPayoutAnalysisDetail',
    },
    component: <SalaryPayoutAnalysisDetail />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/SalaryPayoutAnalysisDetail/Rpt',
    },
    component: <SalaryPayoutAnalysisDetailReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/SalaryPayoutAnalysisDetailByCostCenter',
    },
    component: <SalaryPayoutAnalysisDetailByCostCenter />,
  },
  {
    props: {
      exact: true,
      path:
        '/Payroll/PayrollListing/SalaryPayoutAnalysisDetailByCostCenter/Rpt',
    },
    component: <SalaryPayoutAnalysisDetailByCostCenterReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/SalaryPayoutAnalysisDetailByStaffCategory',
    },
    component: <SalaryPayoutAnalysisDetailByStaffCategory />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/SalaryPayoutAnalysisDetailByStaffCategory/Rpt',
    },
    component: <SalaryPayoutAnalysisDetailByStaffCategoryReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/SalaryPayoutAnalysisDetailByJobPosition',
    },
    component: <SalaryPayoutAnalysisDetailByJobPosition />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/SalaryPayoutAnalysisDetailByJobPosition/Rpt',
    },
    component: <SalaryPayoutAnalysisDetailByJobPositionReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/SalaryPayoutAnalysisSummaryByCostCenter',
    },
    component: <SalaryPayoutAnalysisSummaryByCostCenter />,
  },
  {
    props: {
      exact: true,
      path:
        '/Payroll/PayrollListing/SalaryPayoutAnalysisSummaryByCostCenter/Rpt',
    },
    component: <SalaryPayoutAnalysisSummaryByCostCenterReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/SalaryPayoutAnalysisSummary',
    },
    component: <SalaryPayoutAnalysisSummary />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/SalaryPayoutAnalysisSummary/Rpt',
    },
    component: <SalaryPayoutAnalysisSummaryReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/SalaryPayoutAnalysisSummaryExcelExport',
    },
    component: <SalaryPayoutAnalysisSummaryExcelExport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/StaffCurrentCompensation',
    },
    component: <StaffCurrentCompensation />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/PayrollListing/StaffCurrentCompensation/Rpt',
    },
    component: <StaffCurrentCompensationReport />,
  },
  {
    props: { exact: true, path: '/Payroll/Payslip/PayslipListing/PayslipENBM' },
    component: <PayslipENBM />,
  },
  {
    props: { exact: true, path: '/Payroll/Payslip/PayslipListing/PayslipENBM/Rpt' },
    component: <PayslipENBMReport />,
  },
  {
    props: { exact: true, path: '/Payroll/Payslip/PayslipListing' },
    component: <PayslipListing />,
  },
  {
    props: { exact: true, path: '/Payroll/Payslip/ViewPayslip' },
    component: <ViewPayslip />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingKWSP/GovernmentListingKWSP',
    },
    component: <GovernmentListingKWSP />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingKWSP/BorangA' },
    component: <BorangA />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingKWSP/BorangA/Rpt' },
    component: <BorangAReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingKWSP/GovernmentEPF',
    },
    component: <GovernmentEPF />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingKWSP/GovernmentEPFCsv',
    },
    component: <GovernmentEPFCsv />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingKWSP/GovernmentEPFCsv/Rpt',
    },
    component: <GovernmentEPFCsvReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingKWSP/GovernmentEPFText',
    },
    component: <GovernmentEPFText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingKWSP/GovernmentEPFText/Rpt',
    },
    component: <GovernmentEPFTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingSOCSO/GovernmentListingSOCSO',
    },
    component: <GovernmentListingSOCSO />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingSOCSO/Borang2' },
    component: <Borang2 />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingSOCSO/Borang2/Rpt' },
    component: <Borang2Report />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingSOCSO/Borang3' },
    component: <Borang3 />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingSOCSO/Borang3/Rpt' },
    component: <Borang3Report />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingSOCSO/Borang8A' },
    component: <Borang8A />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingSOCSO/Borang8A/Rpt' },
    component: <Borang8AReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingSOCSO/GovernmentSOCSO',
    },
    component: <GovernmentSOCSO />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingSOCSO/GovernmentSOCSOText',
    },
    component: <GovernmentSOCSOText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingSOCSO/GovernmentSOCSOText/Rpt',
    },
    component: <GovernmentSOCSOTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingSOCSO/GovernmentSOCSOEIS',
    },
    component: <GovernmentSOCSOEIS />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingSOCSO/GovernmentSOCSOEISText',
    },
    component: <GovernmentSOCSOEISText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingSOCSO/GovernmentSOCSOEISText/Rpt',
    },
    component: <GovernmentSOCSOEISTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingEIS/GovernmentEISText',
    },
    component: <GovernmentEISText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingEIS/GovernmentEISText/Rpt',
    },
    component: <GovernmentEISTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingEIS/GovernmentListingEIS',
    },
    component: <GovernmentListingEIS />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingEIS/EISText' },
    component: <EISText />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingEIS/Lampiran1' },
    component: <Lampiran1 />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingEIS/Lampiran1/Rpt' },
    component: <Lampiran1Report />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingLHDN/GovernmentListingLHDN',
    },
    component: <GovernmentListingLHDN />,
  },

  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingLHDN/BorangEListing/BorangEForm',
    },
    component: <BorangEForm />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingLHDN/BorangEListing/BorangEForm/Rpt',
    },
    component: <BorangEReport />,
  },

  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingLHDN/BorangEListing',
    },
    component: <BorangEListing />,
  },

  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingLHDN/BorangEAListing',
    },
    component: <BorangEAListing />,
  },

  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/BorangE' },
    component: <BorangE />,
  },

  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/BorangE2' },
    component: <BorangE2 />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/BorangEAForm' },
    component: <BorangEAForm />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/BorangEAForm/Rpt' },
    component: <BorangEAReport />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/BorangEA' },
    component: <BorangEA />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/BorangEA2022ENBM' },
    component: <BorangEA2022ENBM />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/BorangEA2022ENBM/Rpt' },
    component: <BorangEA2022ENBMReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingLHDN/BorangEA2',
    },
    component: <BorangEA2 />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingLHDN/BorangEA2022',
    },
    component: <BorangEA2022 />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingLHDN/BorangEA2023ENBM',
    },
    component: <BorangEA2023ENBM />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingLHDN/BorangEA2023ENBM/Rpt',
    },
    component: <BorangEA2023ENBMReport />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP159' },
    component: <CP159 />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP159/Rpt' },
    component: <CP159Report />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP21' },
    component: <CP21 />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP21/Rpt' },
    component: <CP21Report />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP22' },
    component: <CP22 />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP22Text' },
    component: <CP22Text />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP22Excel' },
    component: <CP22Excel />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP22Text/TextReport' },
    component: <CP22TextReport />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP22Excel/ExcelReport' },
    component: <CP22ExcelReport />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP22/Rpt' },
    component: <CP22Report />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP22A' },
    component: <CP22A />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP22A/Rpt' },
    component: <CP22AReport />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP22AText' },
    component: <CP22AText />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP22AText/Rpt' },
    component: <CP22ATextReport />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP39' },
    component: <LHDNCP39 />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP39/Rpt' },
    component: <LHDNCP39Report />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP39A' },
    component: <LHDNCP39A />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP39A/Rpt' },
    component: <LHDNCP39AReport />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP8D2023' },
    component: <CP8D2023 />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP8D2023/Rpt' },
    component: <CP8D2023Report />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP8D' },
    component: <CP8D />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP8D/Rpt' },
    component: <CP8DReport />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP8DListing' },
    component: <CP8DListing />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP8D2023Text' },
    component: <CP8D2023Text />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingLHDN/CP8D2023Text/Rpt',
    },
    component: <CP8D2023TextReport />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP8DText' },
    component: <CP8DText />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/CP8DText/Rpt' },
    component: <CP8DTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/GovernmentListingLHDN/CP8DTextListing',
    },
    component: <CP8DTextListing />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/TaxText' },
    component: <TaxText />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/TaxText/Rpt' },
    component: <TaxTextReport />,
  },
  {
    props: { exact: true, path: '/Payroll/ASBListing' },
    component: <ASBListing />,
  },
  {
    props: { exact: true, path: '/Payroll/ASBListing/ASB' },
    component: <ASB />,
  },
  {
    props: { exact: true, path: '/Payroll/ASBListing/ASB/Rpt' },
    component: <ASBReport />,
  },
  {
    props: { exact: true, path: '/Payroll/ASBListing/ASBDownload' },
    component: <ASBCsvText />,
  },
  {
    props: { exact: true, path: '/Payroll/ASBListing/ASBDownload/Rpt' },
    component: <ASBCsvTextReport />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/LampiranB' },
    component: <LampiranB />,
  },
  {
    props: { exact: true, path: '/Payroll/GovernmentListingLHDN/LampiranB/Rpt' },
    component: <LampiranBReport />,
  },
  {
    props: { exact: true, path: '/Payroll/HRDFFundlisting' },
    component: <HRDFFundListing />,
  },

  {
    props: { exact: true, path: '/Payroll/HRDFFundForm' },
    component: <HRDFFundForm />,
  },
  {
    props: { exact: true, path: '/Payroll/HRDFFundlisting/HRDFFundView' },
    component: <HRDFFundView />,
  },

  {
    props: { exact: true, path: '/Payroll/HRDFFundlisting/HRDFFundView2' },
    component: <HRDFFundView2 />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/HRDFFundlisting//HRDFListingReport',
    },
    component: <HRDFFundListingByCompany />,
  },
  {
    props: { exact: true, path: '/Payroll/Zakatlisting' },
    component: <ZakatListing />,
  },
  {
    props: { exact: true, path: '/Payroll/Zakatlisting/ZakatListingWPKL' },
    component: <ZakatWPKL />,
  },
  {
    props: { exact: true, path: '/Payroll/Zakatlisting/ZakatListingWPKL/Rpt' },
    component: <ZakatWPKLReport />,
  },
  {
    props: { exact: true, path: '/Payroll/Zakatlisting/ZakatListingView' },
    component: <ZakatListingView />,
  },
  {
    props: { exact: true, path: '/Payroll/TabungHajiListing' },
    component: <TabungHajiListing />,
  },
  {
    props: { exact: true, path: '/Payroll/TabungHajiListing/TabungHaji' },
    component: <TabungHaji />,
  },
  {
    props: { exact: true, path: '/Payroll/TabungHajiListing/TabungHaji/Rpt' },
    component: <TabungHajiReport />,
  },
  {
    props: { exact: true, path: '/Payroll/TabungHajiListing/TabungHajiExcel' },
    component: <TabungHajiExcel />,
  },
  {
    props: { exact: true, path: '/Payroll/TabungHajiListing/TabungHajiExcel/Rpt' },
    component: <TabungHajiExcelReport />,
  },

  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/BankIntegrationFiles',
    },
    component: <BankIntegrationFiles />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/BankIntegrationFilesListing',
    },
    component: <BankIntegrationFilesListing />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/AFFB/AFFBSalaryDownloadExcel',
    },
    component: <AFFBSalaryDownloadExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/AFFB/AFFBSalaryDownloadExcel/Rpt',
    },
    component: <AFFBSalaryDownloadExcelReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/ABMB/ABMBSalaryDownloadText',
    },
    component: <ABMBSalaryDownloadText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/ABMB/ABMBSalaryDownloadText/Rpt',
    },
    component: <ABMBSalaryDownloadTextReport />,
  },

  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/AMBB/AMBBSalaryDownloadText',
    },
    component: <AMBBSalaryDownloadText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/AMBB/AMBBSalaryDownloadText/Rpt',
    },
    component: <AMBBSalaryDownloadTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/AMMB/AMMBSalaryDownloadText',
    },
    component: <AMMBSalaryDownloadText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/AMMB/AMMBSalaryDownloadText/Rpt',
    },
    component: <AMMBSalaryDownloadTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/AMMB/AMMBSOCSODownloadText',
    },
    component: <AMMBSOCSODownloadText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/AMMB/AMMBSOCSODownloadText/Rpt',
    },
    component: <AMMBSOCSODownloadTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/AMMB/AMMBEISDownloadText',
    },
    component: <AMMBEISDownloadText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/AMMB/AMMBEISDownloadText/Rpt',
    },
    component: <AMMBEISDownloadTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/AMMB/AMMBLHDNDownloadText',
    },
    component: <AMMBLHDNDownloadText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/AMMB/AMMBLHDNDownloadText/Rpt',
    },
    component: <AMMBLHDNDownloadTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/AMMB/AMMBEPFDownloadText',
    },
    component: <AMMBEPFDownloadText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/AMMB/AMMBEPFDownloadText/Rpt',
    },
    component: <AMMBEPFDownloadTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/BIMB/BIMBSalaryText',
    },
    component: <BIMBSalaryText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/BIMB/BIMBSalaryDownloadText',
    },
    component: <BIMBSalaryDownloadText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/BIMB/BIMBSalaryDownloadText/Rpt',
    },
    component: <BIMBSalaryDownloadTextReport />,
  },

  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/BIMB/BIMBSalaryText',
    },
    component: <BIMBSalaryText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HLBB/HLBBSalaryExcel',
    },
    component: <HLBBSalaryExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HLBB/HLBBSalaryDownloadExcel',
    },
    component: <HLBBSalaryDownloadExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HLBB/HLBBSalaryDownloadExcel/Rpt',
    },
    component: <HLBBSalaryDownloadExcelReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HLBB/HLBBEPFExcel',
    },
    component: <HLBBEPFExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HLBB/HLBBEPFDownloadExcel',
    },
    component: <HLBBEPFDownloadExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HLBB/HLBBEPFDownloadExcel/Rpt',
    },
    component: <HLBBEPFDownloadExcelReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HLBB/HLBBSOCSOExcel',
    },
    component: <HLBBSOCSOExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HLBB/HLBBSOCSODownloadExcel',
    },
    component: <HLBBSOCSODownloadExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HLBB/HLBBSOCSODownloadExcel/Rpt',
    },
    component: <HLBBSOCSODownloadExcelReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HLBB/HLBBLHDNExcel',
    },
    component: <HLBBLHDNExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HLBB/HLBBLHDNDownloadExcel',
    },
    component: <HLBBLHDNDownloadExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HLBB/HLBBLHDNDownloadExcel/Rpt',
    },
    component: <HLBBLHDNDownloadExcelReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HLBB/HLBBTabungHajiExcel',
    },
    component: <HLBBTabungHajiExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HLBB/HLBBTabungHajiDownloadExcel',
    },
    component: <HLBBTabungHajiDownloadExcel />,
  },
  {
    props: {
      exact: true,
      path:
        '/Payroll/BankIntegrationFiles/HLBB/HLBBTabungHajiDownloadExcel/Rpt',
    },
    component: <HLBBTabungHajiDownloadExcelReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HLBB/HLBBZakatExcel',
    },
    component: <HLBBZakatExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HLBB/HLBBZakatDownloadExcel',
    },
    component: <HLBBZakatDownloadExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HLBB/HLBBZakatDownloadExcel/Rpt',
    },
    component: <HLBBZakatDownloadExcelReport />,
  },
  {
    props: { exact: true, path: '/Payroll/BankIntegrationFiles/RHB/RHBExcel' },
    component: <RHBExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/RHB/RHBSalaryDownloadExcel',
    },
    component: <RHBSalaryDownloadExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/RHB/RHBSalaryDownloadExcelRpt',
    },
    component: <RHBSalaryDownloadExcelReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/RHB/RHBIBGExcel',
    },
    component: <RHBIBGExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/RHB/RHBIBGSalaryDownloadExcel',
    },
    component: <RHBIBGSalaryDownloadExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/RHB/RHBIBGSalaryDownloadExcelRpt',
    },
    component: <RHBIBGSalaryDownloadExcelReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/RHB/RHBEPFExcel',
    },
    component: <RHBEPFExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/RHB/RHBEPFDownloadExcel',
    },
    component: <RHBEPFDownloadExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/RHB/RHBEPFDownloadExcel/Rpt',
    },
    component: <RHBEPFDownloadExcelReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/RHB/RHBSOCSOExcel',
    },
    component: <RHBSOCSOExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/RHB/RHBSOCSODownloadExcel',
    },
    component: <RHBSOCSODownloadExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/RHB/RHBSOCSODownloadExcel/Rpt',
    },
    component: <RHBSOCSODownloadExcelReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/RHB/RHBEISExcel',
    },
    component: <RHBEISExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/RHB/RHBEISDownloadText',
    },
    component: <RHBEISDownloadText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/RHB/RHBEISDownloadText/Rpt',
    },
    component: <RHBEISDownloadTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/CIMB/CIMBSalaryDownloadText',
    },
    component: <CIMBSalaryDownloadText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/CIMB/CIMBSalaryDownloadText/Rpt',
    },
    component: <CIMBSalaryDownloadTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/RHB/RHBLHDNExcel',
    },
    component: <RHBLHDNExcel />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/RHB/RHBLHDNDownloadText',
    },
    component: <RHBLHDNDownloadText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/RHB/RHBLHDNDownloadText/Rpt',
    },
    component: <RHBLHDNDownloadTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/UOB/UOBSalaryDownloadText',
    },
    component: <UOBSalaryDownloadText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/UOB/UOBSalaryDownloadText/Rpt',
    },
    component: <UOBSalaryDownloadTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/UOB/UOBEISDownloadText',
    },
    component: <UOBEISDownloadText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/UOB/UOBEISDownloadText/Rpt',
    },
    component: <UOBEISDownloadTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/UOB/UOBEPFDownloadText',
    },
    component: <UOBEPFDownloadText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/UOB/UOBEPFDownloadText/Rpt',
    },
    component: <UOBEPFDownloadTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/UOB/UOBSOCSODownloadText',
    },
    component: <UOBSOCSODownloadText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/UOB/UOBSOCSODownloadText/Rpt',
    },
    component: <UOBSOCSODownloadTextReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/UOB/UOBEPFText',
    },
    component: <UOBEPFText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/UOB/UOBSOCSOText',
    },
    component: <UOBSOCSOText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/UOB/UOBEISText',
    },
    component: <UOBEISText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HSBC/HSBCSalaryText',
    },
    component: <HSBCSalaryText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HSBC/HSBCSalaryDownloadText',
    },
    component: <HSBCSalaryDownloadText />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/HSBC/HSBCSalaryDownloadText/Rpt',
    },
    component: <HSBCSalaryDownloadTextReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/Payroll/BankIntegrationFiles/OCBC/OCBCSalary/OCBCSalaryDownloadText',
    },
    component: <OCBCSalaryDownloadText />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/Payroll/BankIntegrationFiles/OCBC/OCBCSalary/OCBCSalaryDownloadTextReport',
    },
    component: <OCBCSalaryDownloadTextReport />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/Payroll/BankIntegrationFiles/OCBC/OCBCLHDN/OCBCLHDNDownloadText',
    },
    component: <OCBCLHDNDownloadText />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/Payroll/BankIntegrationFiles/OCBC/OCBCLHDN/OCBCLHDNDownloadTextReport',
    },
    component: <OCBCLHDNDownloadTextReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/Payroll/BankIntegrationFiles/OCBC/OCBCSOCSO/OCBCSOCSODownloadText',
    },
    component: <OCBCSOCSODownloadText />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/Payroll/BankIntegrationFiles/OCBC/OCBCSOCSO/OCBCSOCSODownloadText/Rpt',
    },
    component: <OCBCSOCSODownloadTextReport />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/Payroll/BankIntegrationFiles/OCBC/OCBCEIS/OCBCEISDownloadText',
    },
    component: <OCBCEISDownloadText />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/Payroll/BankIntegrationFiles/OCBC/OCBCEIS/OCBCEISDownloadText/Rpt',
    },
    component: <OCBCEISDownloadTextReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/Payroll/BankIntegrationFiles/OCBC/OCBCEPF/OCBCEPFDownloadText',
    },
    component: <OCBCEPFDownloadText />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/Payroll/BankIntegrationFiles/OCBC/OCBCEPF/OCBCEPFDownloadText/Rpt',
    },
    component: <OCBCEPFDownloadTextReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/Payroll/BankIntegrationFiles/PBB/PBBSalary/PBBSalaryDownloadExcel',
    },
    component: <PBBSalaryDownloadExcel />,
  },
  {
    props: {
      exact: true,
      path:
        '/Payroll/BankIntegrationFiles/PBB/PBBSalary/PBBSalaryDownloadExcel/Rpt',
    },
    component: <PBBSalaryDownloadExcelReport />,
  },
  {
    props: {
      exact: true,
      path: '/Payroll/BankIntegrationFiles/PBB/PBBSalary/PBBSalaryDownloadText',
    },
    component: <PBBSalaryDownloadText />,
  },
  {
    props: {
      exact: true,
      path:
        '/Payroll/BankIntegrationFiles/PBB/PBBSalary/PBBSalaryDownloadText/Rpt',
    },
    component: <PBBSalaryDownloadTextReport />,
  },
  {
    props: { exact: true, path: '/Leave/LeaveAnalysis' },
    component: <LeaveAnalysis />,
  },
  {
    props: { exact: true, path: '/Leave/LeaveAnalysis/Rpt' },
    component: <LeaveAnalysisReport />,
  },
  {
    props: { exact: true, path: '/Leave/LeaveEntitlementBalanceAnalysis' },
    component: <LeaveEntitlementBalanceAnalysis />,
  },
  {
    props: { exact: true, path: '/Leave/LeaveEntitlementBalanceAnalysis/Rpt' },
    component: <LeaveEntitlementBalanceAnalysisReport />,
  },
  {
    props: {
      exact: true,
      path: '/Claim/ClaimBalanceAnalysis/ClaimBalanceAnalysis',
    },
    component: <ClaimBalanceAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/Claim/ClaimBalanceAnalysis/ClaimBalanceAnalysis/Rpt',
    },
    component: <ClaimBalanceAnalysisReport />,
  },
  {
    props: {
      exact: true,
      path: '/Claim/ClaimStatusAnalaysis/ClaimStatusAnalysis',
    },
    component: <ClaimStatusAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/Claim/ClaimStatusAnalaysis/ClaimStatusAnalysis/Rpt',
    },
    component: <ClaimStatusAnalysisReport />,
  },
  {
    props: {
      exact: true,
      path: '/Claim/ClaimDetailListing/ClaimDetailListing',
    },
    component: <ClaimDetailListing />,
  },
  {
    props: { exact: true, path: '/Claim/StaffClaim/StaffClaim' },
    component: <StaffClaim />,
  },
  {
    props: { exact: true, path: '/Claim/StaffClaim/StaffClaim/Rpt' },
    component: <StaffClaimReport />,
  },
  {
    props: {
      exact: true,
      path: '/Claim/BankClaimIntegrationFiles/BankClaimIntegrationFiles',
    },
    component: <BankClaimIntegrationFiles />,
  },
  {
    props: {
      exact: true,
      path: '/Claim/BankClaimIntegrationFiles/BankClaimIntegrationFilesListing',
    },
    component: <BankClaimIntegrationFilesListing />,
  },
  {
    props: {
      exact: true,
      path: '/Claim/BankClaimIntegrationFiles/HLBB/HLBBClaimExcel',
    },
    component: <HLBBClaimExcel />,
  },
  {
    props: {
      path: '/home/Payroll/BankIntegrationFiles/CIMB/CIMBSalaryDownloadText',
    },
    component: <CIMBSalaryDownloadText />,
  },

  {
    props: {
      path: '/home/Payroll/BankIntegrationFiles/CIMB/CIMBEPFDownloadText',
    },
    component: <CIMBEPFDownloadText />,
  },
  {
    props: {
      path: '/home/Payroll/BankIntegrationFiles/CIMB/CIMBLHDNDownloadText',
    },
    component: <CIMBLHDNDownloadText />,
  },
  {
    props: {
      path: '/home/Payroll/BankIntegrationFiles/CIMB/CIMBSOCSODownloadText',
    },
    component: <CIMBSOCSODownloadText />,
  },
  {
    props: {
      path: '/home/Payroll/BankIntegrationFiles/CIMB/CIMBEISDownloadText',
    },
    component: <CIMBEISDownloadText />,
  },
  {
    props: {
      path: '/home/Payroll/BankIntegrationFiles/CIMB/CIMBSalaryDownloadText',
    },
    component: <CIMBSalaryDownloadText />,
  },

  {
    props: {
      path: '/home/Payroll/BankIntegrationFiles/CIMB/CIMBEPFDownloadText',
    },
    component: <CIMBEPFDownloadText />,
  },
  {
    props: {
      path: '/Payroll/BankIntegrationFiles/CIMB/CIMBEPFDownloadText/Rpt',
    },
    component: <CIMBEPFDownloadTextReport />,
  },
  {
    props: {
      path: '/home/Payroll/BankIntegrationFiles/CIMB/CIMBLHDNDownloadText',
    },
    component: <CIMBLHDNDownloadText />,
  },
  {
    props: {
      path: '/Payroll/BankIntegrationFiles/CIMB/CIMBLHDNDownloadText/Rpt',
    },
    component: <CIMBLHDNDownloadTextReport />,
  },
  {
    props: {
      path: '/home/Payroll/BankIntegrationFiles/CIMB/CIMBZakatDownloadText',
    },
    component: <CIMBZakatDownloadText />,
  },
  {
    props: {
      path: '/Payroll/BankIntegrationFiles/CIMB/CIMBZakatDownloadText/Rpt',
    },
    component: <CIMBZakatDownloadTextReport />,
  },
  {
    props: {
      path: '/home/Payroll/BankIntegrationFiles/CIMB/CIMBSOCSODownloadText',
    },
    component: <CIMBSOCSODownloadText />,
  },
  {
    props: {
      path: '/Payroll/BankIntegrationFiles/CIMB/CIMBSOCSODownloadText/Rpt',
    },
    component: <CIMBSOCSODownloadTextReport />,
  },
  {
    props: {
      path: '/home/Payroll/BankIntegrationFiles/CIMB/CIMBEISDownloadText',
    },
    component: <CIMBEISDownloadText />,
  },
  {
    props: {
      path: '/Payroll/BankIntegrationFiles/CIMB/CIMBEISDownloadText/Rpt',
    },
    component: <CIMBEISDownloadTextReport />,
  },

   //MAYBANK
   {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2UBizSalaryDownloadCsv',
    },
    component: <M2UBizSalaryDownloadCsv />,
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2UBizSalaryDownloadCsvRpt',
    },
    component: <M2UBizSalaryDownloadCsvReport />,
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCSalaryDownloadText',
    },
    component: <M2ERCSalaryDownloadText />,
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCSalaryDownloadTextRpt',
    },
    component: <M2ERCSalaryDownloadTextReport />,
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/MaybankMASAPSDownloadText',
    },
    component: <MaybankMASAPSDownloadText />,
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/MaybankMASAPSDownloadTextRpt',
    },
    component: <MaybankMASAPSDownloadTextReport />,
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCEPFDownload',
    },
    component: <M2ERCEPFDownloadText />,
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCEPFDownloadRpt',
    },
    component: <M2ERCEPFDownloadTextReport />,
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCTaxDownload',
    },
    component: <M2ERCTaxDownloadText />,
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCTaxDownloadRpt',
    },
    component: <M2ERCTaxDownloadTextReport />,
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCSOCSODownload',
    },
    component: <M2ERCSOCSODownloadText />,
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCSOCSODownloadRpt',
    },
    component: <M2ERCSOCSODownloadTextReport />,
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCEISDownload',
    },
    component: <M2ERCEISDownloadText />,
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCEISDownloadRpt',
    },
    component: <M2ERCEISDownloadTextReport />,
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCZakatDownload',
    },
    component: <M2ERCZakatDownloadText />,
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCZakatDownloadRpt',
    },
    component: <M2ERCZakatDownloadTextReport />,
  },
  {
    props: { exact: true, path: '/TimeAttendance/TimeAttendanceAnalysis' },
    component: <TimeAttendanceAnalysis />,
  },
  {
    props: { exact: true, path: '/TimeAttendance/AttendanceListingRpt' },
    component: <AttendanceListingReport />,
  },
  {
    props: { exact: true, path: '/TimeAttendance/AttendanceListingRptExcel' },
    component: <AttendanceListingReportExcel />,
  },
  {
    props: { exact: true, path: '/TimeAttendance/EmployeeClockingListingRpt' },
    component: <EmployeeClockingListingReport />,
  },
  {
    props: {
      exact: true,
      path: '/TimeAttendance/EmployeeClockingListingRptExcel',
    },
    component: <EmployeeClockingListingReportExcel />,
  },
  {
    props: { exact: true, path: '/TimeAttendance/AbsenteeismListingRpt' },
    component: <AbsenteeismListingReport />,
  },
  {
    props: { exact: true, path: '/TimeAttendance/AbsenteeismListingRptExcel' },
    component: <AbsenteeismListingReportExcel />,
  },
  {
    props: { exact: true, path: '/TimeAttendance/IncompleteClockingListingRpt' },
    component: <IncompleteClockingListingReport />,
  },  
  {
    props: {
      exact: true,
      path: '/TimeAttendance/IncompleteClockingListingRptExcel',
    },
    component: <IncompleteClockingListingReportExcel />,
  },
  {
    props: { exact: true, path: '/TimeAttendance/IrregularAttendanceListingRpt' },
    component: <IrregularAttendanceListingReport />,
  },
  {
    props: {
      exact: true,
      path: '/TimeAttendance/IrregularAttendanceListingRptExcel',
    },
    component: <IrregularAttendanceListingReportExcel />,
  },
  {
    props: { exact: true, path: '/TimeAttendance/LateInEarlyOutListingRpt' },
    component: <LateInEarlyOutListingReport />,
  },
  {
    props: {
      exact: true,
      path: '/TimeAttendance/LateInEarlyOutListingRptExcel',
    },
    component: <LateInEarlyOutListingReportExcel />,
  },
  {
    props: { exact: true, path: '/TimeAttendance/PeriodicOvertimeForecastListing' },
    component: <PeriodicOvertimeForecastListingReport />,
  },
  {
    props: { exact: true, path: '/TimeAttendance/PeriodicOvertimeForecastListingReportExcel' },
    component: <PeriodicOvertimeForecastListingReportExcel />,
  },
  
  {
    props: { exact: true, path: '/Helpdesk/HelpdeskListingReport' },
    component: <HelpdeskListingReport />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectTimesheet/ProjectTimesheet',
    },
    component: <ProjectTimesheet />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectTimesheet/ProjectTimesheet/Rpt',
    },
    component: <ProjectTimesheetReport />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectTimesheet/ProjectTimesheetSummary',
    },
    component: <ProjectTimesheetSummary />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectTimesheet/ProjectTimesheetSummary/Rpt',
    },
    component: <ProjectTimesheetSummaryReport />,
  },
]

export default DigitalReportingManagementRoutes
